/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: app.js
**/

import UltimsoftWebsiteTos from "./pages/ultimsoft_website_tos";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { themeDark, themeLight } from "./assets/styles/themes";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AiroadLegals from "./pages/airoad_legals";
import OurProject from "./pages/our_projects";
import BartyTos from "./pages/barty_tos";
import Barty from "./pages/barty";
import Contact from "./pages/contact";
import React from "react";

function App() {
  const [isLight] = React.useState(true);

  return (
      <ThemeProvider theme = { isLight ? themeLight : themeDark }>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path = '/' element = { <OurProject /> } />
            <Route path = "/contact" element = { <Contact /> } />
            <Route path = "/barty" element = { <Barty /> } />
            <Route path = "/barty/terms-of-services" element = { <BartyTos /> } />
            <Route path = "/airoad/terms-of-services" element = { <AiroadLegals legalType = "full_tos"/> } />
            <Route path = "/airoad/terms" element = { <Navigate to="/airoad/terms-of-services" /> } />
            <Route path = "/airoad/tos" element = { <Navigate to="/airoad/terms-of-services" /> } />
            <Route path = "/airoad/privacy-policy" element = { <AiroadLegals legalType = "privacy-policy"/> } />
            <Route path = "/airoad/privacy" element = { <Navigate to="/airoad/privacy-policy" /> } />
            <Route path = "/legal" element = { <UltimsoftWebsiteTos /> } />
            <Route path="/bartyTos.html" element={<Navigate to="/barty/terms-of-services" />} />
            <Route path="/barty.html" element={<Navigate to="/barty" />} />
            <Route path="/support" element={<Navigate to="/contact" />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
  );
}

export default App;
