/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: barty_tos.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";
import NavBar from "../components/NavBar/NavBar";
import palette from "../assets/styles/colors";
import Intro from "../components/barty/intro";
import FirstScroll from "../components/barty/first_scroll";
import LastScroll from "../components/barty/last_scroll";
import Footer from "../components/footer/footer";

const Barty = () => {
  const [fontSize, setFontsize] = React.useState(
    window.innerWidth > 1600 ? "1vw" :
      window.innerWidth > 1300 ? "1.2vw" :
        window.innerWidth > window.innerHeight ? "1.5vw" :
          window.innerWidth > 600 ? "2.5vw" : "2.7vw"
  );
  const [
    isWithLargerThanHeight,
    setIsWithLargerThanHeight
  ] = React.useState(window.innerWidth > window.innerHeight);

  React.useEffect(() => {
    function handleResize() {
      setFontsize(
        window.innerWidth > 1600 ? "1vw" :
          window.innerWidth > 1300 ? "1.2vw" :
            window.innerWidth > window.innerHeight ? "1.5vw" :
              window.innerWidth > 600 ? "2.5vw" : "2.7vw"
      );
      setIsWithLargerThanHeight(window.innerWidth > window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
  });

  return (
    <Grid
      container
      direction = "column"
      justifyContent = "center"
      width = "100vw"
      backgroundColor = { "black" }
    >
      <NavBar color = { palette.themeDarkTextColor } fontSize = { fontSize }
        isWithLargerThanHeight = { isWithLargerThanHeight }
        highLight = "projects"
      />
      <Grid container
          padding = "5vh 0 0 0"
        sx = {{
          width: "100%",
          textAlign: "justify",
          color: palette.themeDarkTextColor
        }}
      >
        
        <Intro fontSize = { fontSize }/>
        <FirstScroll fontSize = { fontSize }/>
        <LastScroll fontSize = { fontSize }/>
        <Grid item width = "100%" marginTop = "4vh">
        <Typography fontSize = { fontSize } textAlign = "center"
         fontFamily = "MontserratRegular" width = "100%">
          Copyright © 2021 Ultimsoft SAS. All rights reserved.
        </Typography>
        </Grid>
      </Grid>
      <Grid item>
          <Footer fontSize = { fontSize } fontColor = "white"
          showTradeMark = { true }/>
        </Grid>
    </Grid>
  );
};

export default Barty;
