/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: last_scroll.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";
import bartyDarkMockup from "../../assets/media/bartyDarkMockup.jpg";

const LastScroll = (prop) => {
  const {
    fontSize
  } = prop

  return (
    <Grid item style={{
      textAlign: "center",
      width: "100%",
      padding: "10vh 0 0 0",
      height: "100%"
    }}>
      <Typography
        fontFamily="MontserratRegular"
        fontSize={fontSize}
        fontWeight="300"
        padding="0 0 4vh 0"
      >
        <strong>Rejoins la liste d'invitations maintenant</strong> et
        <strong> parles-en autour de toi,</strong> pour
        <strong> avoir accès à Barty avant tout le monde.</strong>
      </Typography>
      <Typography
        fontFamily="MontserratRegular"
        fontSize={fontSize}
        fontWeight="600"
        padding="0 0 3vh 0"
      >
        Barty<br />Enjoy life with friends
      </Typography>
      <img src={bartyDarkMockup} width="100%" alt="barty_mockup.png" />
    </Grid>

  );
};

export default LastScroll;
