/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: our_projects.js
**/

import React from "react";
import { Grid } from "@mui/material";
import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/footer/footer";
import DisplayProject from "../components/ourProjects/display_project";

const OurProject = () => {
  const [currentProject] = React.useState("AIRoad");
  const [fontSize, setFontsize] = React.useState(
    window.innerWidth > 1600 ? "1vw" :
      window.innerWidth > 1300 ? "1.2vw" :
        window.innerWidth > window.innerHeight ? "1.5vw" :
          window.innerWidth > 600 ? "2.5vw" : "2.7vw"
  );
  const [
    isWithLargerThanHeight,
    setIsWithLargerThanHeight
  ] = React.useState(window.innerWidth > window.innerHeight);

  React.useEffect(() => {
    function handleResize() {
      setFontsize(
        window.innerWidth > 1600 ? "1vw" :
          window.innerWidth > 1300 ? "1.2vw" :
            window.innerWidth > window.innerHeight ? "1.5vw" :
              window.innerWidth > 600 ? "2.5vw" : "2.7vw"
      );
      setIsWithLargerThanHeight(window.innerWidth > window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
  });
  return (
    <Grid
      container
      direction = "column"
      justifyContent = "center"
      width = "100vw"
    >
      <DisplayProject currentProject = { currentProject } color = "white"
        isWithLargerThanHeight = { isWithLargerThanHeight }
        fontSize = { fontSize }
      />
      <NavBar color = "white" fontSize = { fontSize }
        isWithLargerThanHeight = { isWithLargerThanHeight }
        highlight = "projects"
      />
      <Grid item
      sx = {{
        position: "absolute",
        top: "93vh",
        left: "0"
      }}>
        <Footer showTradeMark = { false }
        fontSize = { fontSize } fontColor = "white"/>
      </Grid>
    </Grid>
  );
};

export default OurProject;
