/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: point_two_register.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";

const PointTwoDotOne = (prop) => {
    const {
      fontSize
    } = prop;
    return (
      <Grid
        container
        direction = "column"
      >
          <Grid item>
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratBold"
          >
            2.1. Conditions d'inscription à BARTY
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            L'utilisation de BARTY est réservée aux personnes physiques et 
            morales.<br/><br/>
            Les personnes physiques mineurs âgées de 16 ans ou plus, à la 
            condition qu'ils aient préalablement obtenu du (des) titulaire(s) 
            de l'autorité parentale les concernant, l'autorisation de le faire 
            et que le (les) titulaire(s) de l'autorité parentale a (ont) 
            accepté d'être garant(s) du respect par l'utilisateur mineur des 
            CGU.<br/><br/>
            Toute utilisation des Services disponibles sur l'Application par un 
            Utilisateur mineur ayant atteint l'âge minimum de 16 ans est 
            effectuée sous l'entière responsabilité du (des) titulaire(s) de 
            l'autorité parentale sur l'utilisateur mineur concerné.<br/><br/>
            En cas d'acceptation des présentes CGU par un Utilisateur qui 
            utilise les Services au nom d'une société, organisation, État ou 
            autre entité juridique, l'Utilisateur déclare et garantit qu'il est 
            autorisé à le faire et qu'il a l'autorité de lier cette entité aux 
            présentes CGU.<br/><br/>
            Vous n'avez pas la permission de créer un nouveau compte si nous 
            avons désactivé, restreint, ou supprimer votre précédent Compte.
            <br/><br/>
          </Typography>
        </Grid>
      </Grid>
    );
};

const PointTwoDotThree = (prop) => {
    const {
      fontSize
    } = prop;
    return (
      <Grid
        container
        direction = "column"
      >
        <Grid item>
            <Typography fontSize = { fontSize }
                fontFamily = "MontserratBold"
            >
                2.3. Contenu diffusé par l'Utilisateur
            </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
            <Typography fontSize = { fontSize }
                fontFamily = "MontserratRegular"
            >
                L'Utilisateur garantit expressément avoir obtenu tous les droits et autorisations
                  nécessaires pour la publication de tout Contenu ou annonce d'Évènement.
              L'Utilisateur s'engage à ce que le Contenu et annonce d'Événement:
            </Typography>
        </Grid>
        <Grid item marginLeft = "4%">
            <Typography fontSize = { fontSize }
                fontFamily = "MontserratRegular"
            >
                - Ne donne pas accès par le biais de liens hypertextes à des 
                sites Internet dont le Contenu est contraire aux CGU BARTY, à 
                la Charte BARTY, à la législation ou réglementation en vigueur;
                <br/><br/>
                - Ne présente pas des informations fausses, mensongères ou de 
                nature à nuire aux intérêts ou à l'image de BARTY ou de tout 
                tiers;<br/><br/>
                - Ne contienne aucun Contenu portant atteinte aux droits de 
                propriété intellectuelle de tiers, aux Contenus choquants, 
                illégaux...<br/><br/>
            </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
            <Typography fontSize = { fontSize }
                fontFamily = "MontserratRegular"
            >
                L'Utilisateur déclare avoir pris toutes précautions pour 
                respecter la législation en vigueur et décharger BARTY de 
                toutes responsabilités à cet égard.<br/><br/>
                <strong>
                Ainsi, l'Utilisateur déclare qu'il est seul responsable du 
                Contenu des Évènements qu'il publie et rend accessibles, et de 
                tout document ou information qu'il transmet aux Utilisateurs.
                <br/><br/>
                </strong>
                <strong>
                En soumettant, en publiant ou en affichant un Contenu sur ou 
                via les Services, vous nous accordez une licence mondiale, non 
                exclusive et libre de redevances (incluant le droit de 
                sous-licencier), nous autorisant à utiliser, copier, 
                reproduire, traiter, adapter, modifier, publier, transmettre, 
                afficher et distribuer ce Contenu sur tout support et selon 
                toute méthode de distribution actuellement connus ou développés 
                dans le futur (dans un souci de clarté, ces droits comprennent, 
                par exemple, ceux de conservation, transformation et 
                traduction). Cette licence nous autorise à mettre votre Contenu 
                à disposition des autres Utilisateurs, des Annonceurs et/ou 
                tiers.<br/><br/>
                Vous déclarez et garantissez que vous avez, ou avez obtenu, 
                tous les droits, licences, consentements, autorisations, le 
                pouvoir et/ou l'autorité nécessaires pour accorder les droits 
                concédés aux termes des présentes pour tout Contenu que vous 
                soumettez, postez ou affichez sur ou par le biais des Services. 
                Vous acceptez que ce Contenu ne contienne pas d'éléments soumis 
                au droit d'auteur ou d'autres droits de propriété, à moins que 
                vous n'ayez l'autorisation nécessaire ou que vous ayez 
                légalement le droit de publier les éléments et d'accorder à 
                BARTY la licence décrite ci-dessus.<br/><br/>
                En conséquence, vous garantissez BARTY, ses Annonceurs et/ou 
                tiers contre toute revendication de droits sur le Contenu 
                publié ou condamnation qui pourrait être prononcée à leur 
                encontre et suite à un recours ou une action engagée par tout 
                tiers et dégage expressément BARTY, ses Annonceurs et/ou tiers 
                de toute responsabilité qu'elle pourrait encourir. 
                L'Utilisateur supportera ainsi tous les dommages-intérêts, les 
                frais et dépens auxquels il pourrait être condamné.<br/><br/>
                Tout Utilisateur reconnaît et accepte que BARTY puisse ne pas 
                publier ou supprimer, à tout moment, sans indemnité ni droit à 
                remboursement des sommes engagées suite à la souscription à des 
                Options Payantes, un Évènement, un Contenu et/ou un Compte 
                Utilisateur qui serait contraire à la loi française, aux 
                présentes CGU de l'Application BARTY, et/ou susceptible de 
                porter atteinte aux droits de tiers.<br/><br/>
                </strong>
                Tout Évènement public sera visible par l'ensemble des 
                Utilisateurs hormis les Évènements privés qui ne seront eux 
                visibles que par les Utilisateurs invités et par l'équipe BARTY.
            </Typography>
        </Grid>
      </Grid>
    );
};

const PointTwoDotTwo = (prop) => {
    const {
      fontSize
    } = prop;
    return (
      <Grid
        container
        direction = "column"
      >
        <Grid item>
            <Typography fontSize = { fontSize }
                fontFamily = "MontserratBold"
            >
                2.2. Création de Compte
            </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
            <Typography fontSize = { fontSize }
                fontFamily = "MontserratRegular"
            >
                BARTY permet à l'Utilisateur de créer, rejoindre et rechercher 
                des Évènements.<br/><br/>
                BARTY permet aux Utilisateurs d'accéder à toutes les 
                informations disponibles sur l'Évènement notamment la date, la 
                description, le lieu et les photos ainsi que d'interagir entre 
                Utilisateurs pour organiser et participer aux Évènements.<br/>
                <br/>
                Pour accéder à l'ensemble des Services proposés, l'Utilisateur 
                devra obligatoirement se créer un Compte Utilisateur.<br/><br/>
                La mise en ligne d'Évènement se fait obligatoirement sur 
                inscription. L'inscription entraîne automatiquement l'ouverture 
                d'un Compte au nom de l'Utilisateur, lui donnant accès à un 
                Compte utilisateur, qui lui permet de gérer l'utilisation de 
                l'ensemble des Services de BARTY.<br/><br/>
                Pour créer votre Compte utilisateur, vous devez remplir 
                l'ensemble des champs obligatoires figurant sur le formulaire 
                d'inscription.<br/><br/>
                A l'occasion de la création de votre Compte Utilisateur, vous 
                vous engagez à fournir des informations personnelles exactes et 
                conformes à la réalité et à les mettre à jour, par 
                l'intermédiaire de votre profil. Vous êtes responsable de toute 
                activité sur votre Compte.<br/><br/>
                L'Utilisateur est informé et accepte que les informations 
                saisies aux fins de création ou de mise à jour de son Compte 
                utilisateur vaillent preuve de son identité. Les informations 
                saisies par l'Utilisateur l'engagent dès leur validation.<br/>
                <br/>
                L'Utilisateur s'identifiera via ses données de connexion en 
                saisissant d'une part, son adresse email déclaré lors de la 
                création de son compte et d'autre part, son mot de passe 
                personnalisé.<br/><br/>
                <strong>
                Afin de maximiser la sécurité de votre Compte, vous devez 
                choisir un mot de passe robuste de 12 caractères minimum, d'une 
                complexité forte c'est à dire avec des lettres minuscules, 
                lettres majuscules, des chiffres, et des caractères spéciaux, 
                les 12 caractères de votre mot de passe ne doivent pas avoir de 
                signification ou de liens logiques entre eux, et surtout ne 
                l'utilisez nulle part d'autres que sur les Services, et ne le 
                transmettez à personne sous aucun prétexte.<br/><br/>
                </strong>
                <i><strong>
                NOUS NE VOUS DEMANDERONS JAMAIS DE NOUS COMMUNIQUER VOTRE MOT 
                DE PASSE.<br/><br/>
                </strong></i>
                Les données de connexion sont strictement personnelles. 
                L'Utilisateur doit immédiatement contacter BARTY par email à : 
                security@ultimsoft.com ou via le formulaire de contact, s'il 
                remarque que son Compte utilisateur a été utilisé à son insu. 
                Il donne droit à BARTY de prendre toutes mesures appropriées 
                pour résoudre le problème.<br/><br/>
                Vous vous engagez à ne pas créer ou utiliser, sous votre propre 
                identité ou celle d'un tiers, d'autres Comptes que celui 
                initialement créé.
            </Typography>
        </Grid>
      </Grid>
    );
};

const PointTwoRegister = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
        <Grid item>
            <Typography fontSize = { fontSize }
                fontFamily = "MontserratBold"
            >
                2. Inscription à l'application BARTY – Création du Compte et Contenu
            </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
            <PointTwoDotOne fontSize = { fontSize }/>
            <PointTwoDotTwo fontSize = { fontSize }/>
            <PointTwoDotThree fontSize = { fontSize }/>
        </Grid>
    </Grid>
  );
};

export default PointTwoRegister;
