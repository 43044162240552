import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { PropTypes } from "prop-types";

const NavBarButton = (props) => {
  const {
    textButton,
    borderButton,
    fontFamilyButton,
    colorButton,
    textTransformButton,
    fontSizeButton,
    lineHeightButton,
    variantButton,
    hoverColorButton,
    route,
    background,
    fontColor
  } = props;

  return (
    <Grid>
      <Button
        variant={variantButton}
        sx={{
          ":hover": {
            bgcolor: hoverColorButton,
          },
        }}
        color = { colorButton }
        href={route}
        style={{
          textTransform: textTransformButton,
          border: borderButton,
          background: background
        }}>
        <Typography
          fontFamily={fontFamilyButton}
          fontSize={fontSizeButton}
          lineHeight={lineHeightButton}
          color = { fontColor }
        >
          {textButton}
        </Typography>
      </Button>
    </Grid>
  )
};

NavBarButton.propTypes = {
  borderButton: PropTypes.string,
  fontFamilyButton: PropTypes.string,
  colorButton: PropTypes.string,
  textTransformButton: PropTypes.string,
  fontSizeButton: PropTypes.string,
  lineHeightButton: PropTypes.string,
  variantButton: PropTypes.string,
  hoverColorButton : PropTypes.string,
  fontColor: PropTypes.string,
  fontWeight: PropTypes.string
};

NavBarButton.defaultProps = {
  fontFamilyButton: "MontserratRegular",
  colorButton: "primary",
  fontSizeButton: "1.2vw",
  lineHeightButton: "normal",
  variantButton: "text",
  hoverColorButton: "#9AB0ED",
  background: "transparent",
  fontColor: ""
};

export default NavBarButton;