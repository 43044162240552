/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: point_four_services_usage.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";

const PointFourDotOne = (prop) => {
    const {
      fontSize
    } = prop;
    return (
      <Grid
        container
        direction = "column"
      >
          <Grid item>
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratBold"
          >
            4.1. Publication des Évènements
          </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            En tant qu'Utilisateur et sous réserve que vous remplissez les 
            conditions décrites à l'Article 2, vous pouvez créer, rejoindre et 
            publier des Évènements. Vous pouvez également publier des annonces 
            de Covoiturage en indiquant des informations quant au trajet en 
            Covoiturage que vous comptez effectuer.<br/><br/>
            L'Utilisateur accepte de fournir à BARTY des informations exactes, 
            complètes et à jour et ne sont entachées d'aucun caractère 
            trompeur. L'Utilisateur est responsable de toute activité sur son 
            Compte. Il est donc important de veiller à préserver la sécurité de 
            son compte.<br/><br/>
            Pour accéder à BARTY, tout Utilisateur déclare être informé qu'il 
            devra disposer d'un accès à Internet dont le coût est à sa charge 
            et reconnaît que :
          </Typography>
        </Grid>
        <Grid item marginLeft = "4%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            - La fiabilité des transmissions est aléatoire et que des pannes ou 
            saturations peuvent intervenir. Il doit prendre toute mesure qu'il 
            estimera appropriée pour assurer la sécurité de son équipement et 
            de ses propres données, logiciels ou autres, notamment contre la 
            contamination par tout virus et/ou de tentative d'intrusion et/ou 
            renifleur de données;<br/>
            - Tout équipement connecté à l'Application BARTY est et reste sous 
            son entière responsabilité. La responsabilité de BARTY ne pourra 
            pas être recherchée pour tout dommage direct ou indirect qui 
            pourrait subvenir du fait de sa connexion à l'Application.
          </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            Il s'engage à mettre à jour le Contenu des Évènements en cas de 
            modification, afin qu'ils n'induisent pas en erreur les 
            Utilisateurs de l'Application.
          </Typography>
        </Grid>
    </Grid>
  );
};

const PointFourDotTwo = (prop) => {
    const {
      fontSize
    } = prop;
    return (
      <Grid
        container
        direction = "column"
      >
        <Grid item>
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratBold"
          >
            4.2. Obligations de l'Utilisateur
          </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            L'Utilisateur garantit et s'engage expressément à :
          </Typography>
        </Grid>
        <Grid item marginLeft = "4%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            - garder confidentielles ses informations de connexion aux Services,
            <br/>
            - ne pas transmettre son mot de passe à des tierces personnes ou 
            services,<br/>
            - ne pas transmettre et ni télécharger de virus, de cheval de Troie 
            ou tout autre programme indésirable en utilisant l'Application,<br/>
            - ne pas reconstituer ou tenter de reconstituer, à partir des 
            Données et/ou au moyen du Service BARTY, une base de données visant 
            à offrir directement ou indirectement, à titre gratuit ou onéreux, 
            le même service ou un service comparable, à des personnes non 
            autorisées ou même à des Utilisateurs dans le but de contourner le 
            Service BARTY dont bénéficie l'Utilisateur au titre de l'Offre 
            qu'il a souscrite,<br/>
            - ne pas diffuser ou vendre, de quelque manière que ce soit, des 
            Données obtenues par l'intermédiaire du Service BARTY aux fins 
            d'aider une personne à reconstituer, en tout ou partie, une base de 
            données, quelle qu'elle soit, ayant vocation à être diffusé à des 
            tiers, ou un service équivalent,<br/>
            - s'interdire de pratiquer de l'ingénierie inversée ( Reverse 
            Engineering), décompiler, désassembler, déchiffrer ou autrement 
            tenter d'obtenir le code source en relation avec toute propriété 
            intellectuelle sous-jacente utilisée pour fournir tout ou partie 
            des Services,<br/>
            - s'abstenir de tout acte susceptible de porter atteinte à la vie 
            privée, à l'honneur, à la sensibilité, à l'image de marque de BARTY 
            et de la société ULTIMSOFT SAS, des entités tierces, à la notoriété 
            de toute personne, physique ou morale, et notamment à la Société 
            ULTIMSOFT SAS, en évitant toute mention, message ou texte 
            diffamant, provocant, malveillant, dénigrant ou menaçant sur 
            quelque support que ce soit connu ou inconnu à ce jour,<br/>
            - respecter et faire respecter les présentes conditions 
            d'utilisation et de vente.
          </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            L'Utilisateur s'engage à utiliser l'Application de manière loyale, 
            conformément à sa finalité professionnelle, aux dispositions 
            légales, règlementaires ainsi qu'aux présentes Conditions aux 
            usages en vigueur.<br/><br/>
            <strong>
            Nous nous réservons la possibilité de supprimer ou de suspendre 
            sans délai toute habilitation en cas de suspicion d'utilisation non 
            conforme ou injustifiée par l'Utilisateur.
            </strong>
          </Typography>
        </Grid>
    </Grid>
  );
};

const PointFourDotThreeDotOne = (prop) => {
    const {
      fontSize
    } = prop;
    return (
      <Grid
        container
        direction = "column"
      >
        <Grid item>
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratBold"
          >
            4.3.1 Engagements de l'Utilisateur conducteur
          </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            L'Utilisateur conducteur s'engage à :
          </Typography>
        </Grid>
        <Grid item marginLeft = "4%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            - Être titulaire d'un permis de conduire valide et d'une assurance 
            couvrant les passagers.<br/>
            - Seul le titulaire du véhicule a le droit de conduire son 
            véhicule.<br/>
            - Utiliser son véhicule personnel ou, si c'est une voiture de 
            fonction dont les frais sont pris en charge par l'employeur, à ne 
            pas faire payer à son profit les passagers pour les frais du trajet.
            <br/>
            - Utiliser un véhicule en parfait état de fonctionnement dont le 
            contrôle technique est à jour.<br/>
            - Se rendre au point de rendez-vous défini au préalable avec 
            l'Utilisateur et le déposer au point d'arrivé convenu entre eux. En 
            cas d'incapacité à se rendre au point de rendez-vous à l'heure 
            prévue, informer les Utilisateurs immédiatement du retard prévu ou 
            de l'annulation du trajet.<br/><br/>
            - Ne pas être en possession de substances illicites ou avoir 
            consommé des substances pouvant altérer sa conduite pendant le 
            trajet (alcool, drogue, médicaments…)<br/>
            - Ne pas réaliser de bénéfices sur les trajets réalisés, sous peine 
            de sanctions prévues à l'article L.132-2 du Code de la consommation.
            <br/>
            - Signaler un Utilisateur au comportement non approprié à BARTY.
            <br/>
            - Ne pas utiliser le téléphone au volant et surtout respecter le 
            code de la route.
          </Typography>
        </Grid>
    </Grid>
  );
};

const PointFourDotThreeDotTwo = (prop) => {
    const {
      fontSize
    } = prop;
    return (
      <Grid
        container
        direction = "column"
      >
        <Grid item>
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratBold"
          >
            4.3.2. Engagements de l'Utilisateur passager
          </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            L'Utilisateur passager s'engage à :
          </Typography>
        </Grid>
        <Grid item marginLeft = "4%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            - Respecter le point et l'heure de rendez-vous définis avec 
            l'Utilisateur conducteur, ainsi que le lieu d'arrivée convenu.<br/>
            <br/>
            - Adopter une attitude responsable pendant la durée du Trajet, qui 
            ne compromettrait pas la capacité de l'Utilisateur conducteur à 
            conduire en toute sécurité.<br/><br/>
            - Respecter l'état de propreté et l'intégrité du véhicule.<br/><br/>
            - Informer immédiatement l'Utilisateur conducteur si le rendez-vous 
            prévu ne peut plus être tenu dans les conditions préalablement 
            convenues (annulation, retard…).<br/><br/>
            - Signaler un Utilisateur au comportement non approprié à BARTY.
          </Typography>
        </Grid>
    </Grid>
  );
};

const PointFourDotThree = (prop) => {
    const {
      fontSize
    } = prop;
    return (
      <Grid
        container
        direction = "column"
      >
        <Grid item>
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratBold"
          >
            4.3. Engagements de l'Utilisateur dans le cadre du Covoiturage
          </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
          <PointFourDotThreeDotOne fontSize = { fontSize }/>
          <PointFourDotThreeDotTwo fontSize = { fontSize }/>
        </Grid>
    </Grid>
  );
};

const PointFourDotFour = (prop) => {
    const {
      fontSize
    } = prop;
    return (
      <Grid
        container
        direction = "column"
      >
        <Grid item>
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratBold"
          >
            4.4. Notification des abus
          </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            <strong>
            Nous n'avons pas vocation à réguler ou modérer les Contenus et/ou 
            propos publiés sur BARTY pour les Contenus publics ou privés 
            (publication d'évènements, chats, médias, ...).<br/><br/>
            Nous nous réservons le droit de supprimer sans préavis ni indemnité 
            ni droit à remboursement, votre Contenu et ou votre Compte avec ou 
            sans raison, à notre seule discrétion.<br/>
            Même si nous avons la possibilité de supprimer tout Contenu nous ne 
            sommes pas tenus de le faire.<br/>
            BARTY se dégage de toute responsabilité des Contenus contraire à la 
            loi.<br/><br/>
            Les publications des Contenus sont sous la responsabilité exclusive 
            de l'Utilisateur l'ayant publié.</strong><br/><br/>
            Tout Utilisateur peut signaler un abus à partir de l'Application en 
            rentrant en contact avec BARTY en cliquant sur le lien « Contact » 
            dans les paramètres.<br/><br/>
            De manière générale, toute forme d'apologie ou d'incitation à faire 
            quelque chose d'illégal est prohibée.<br/><br/>
            Sont considérés comme manifestement illicites les Contenus et/ou 
            propos suivants (sans que cette liste ne soit exhaustive) :<br/>
            <br/>
          </Typography>
        </Grid>
        <Grid item marginLeft = "4%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            - toute incitation à la consommation d'alcool ou de tabac;<br/>
            - toute incitation à la consommation ou propos positif sur les 
            produits stupéfiants;<br/>
            - appel à la violence, à la haine, au viol, au meurtre, au suicide;
            <br/>
            - pédophilie;<br/>
            - pornographie enfantine;<br/>
            - toute atteinte à la dignité humaine;<br/>
            - toute discrimination ou appel à la discrimination basée sur 
            l'ethnie, le sexe, les origines, les croyances et religions.
          </Typography>
        </Grid>
        <Grid item marginLeft = "4%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            Les Utilisateurs auteurs de tels Évènements et/ou messages seront 
            définitivement bannis de BARTY conformément aux présentes CGU.<br/>
            <br/>
            Nous informerons les autorités compétentes de la tentative de 
            publication de tels Contenus et/ou propos et communiquerons à ces 
            dernières l'ensemble des informations d'identification dont nous 
            disposons.
          </Typography>
        </Grid>
    </Grid>
  );
};

const PointFourServicesUsage = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
        <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          4. Utilisation des Services
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <PointFourDotOne fontSize = { fontSize}/>
        <PointFourDotTwo fontSize = { fontSize}/>
        <PointFourDotThree fontSize = { fontSize}/>
        <PointFourDotFour fontSize = { fontSize}/>
      </Grid>
    </Grid>
  );
};

export default PointFourServicesUsage;
