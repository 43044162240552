/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: airoad_legals.js
**/

import React, { useEffect, useState } from "react";
import httpRequest from "../backend/http_request";
import { Grid } from "@mui/material";
import NavBar from "../components/NavBar/NavBar";
import palette from "../assets/styles/colors";
import Footer from "../components/footer/footer";
import { useIntl } from "react-intl";
import TosBeautifier from "../components/tos_beautifier";

const AiroadLegals = (prop) => {
  const {
    legalType
  } = prop;
  const [fontSize, setFontsize] = React.useState(
    window.innerWidth > 1600 ? 1 :
      window.innerWidth > 1300 ? 1.2 :
        window.innerWidth > window.innerHeight ? 1.5 :
          window.innerWidth > 600 ? 2.5 : 2.7
  );
  const [
    isWithLargerThanHeight,
    setIsWithLargerThanHeight
  ] = React.useState(window.innerWidth > window.innerHeight);
  React.useEffect(() => {
    function handleResize() {
      setFontsize(
        window.innerWidth > 1600 ? 1 :
          window.innerWidth > 1300 ? 1.2 :
            window.innerWidth > window.innerHeight ? 1.5 :
              window.innerWidth > 600 ? 2.5 : 2.7
      );
      setIsWithLargerThanHeight(window.innerWidth > window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
  });
  const [tos, setTos] = useState("");
  var language = useIntl();
    language = (
      language.locale.toString()[0] + language.locale.toString()[1]
    ).toUpperCase();

  var personalDataPolicyTranslation = useIntl().formatMessage({id: "personalDataProcessingPolicy"});
  useEffect(() => {
    async function _getTos(language) {
      var requestTos = await httpRequest(
        `https://airoad.ultimsoft-cloud-engine.com/api/v1/public/legal/tos?lang=${language}`,
        "GET",
        "",
        {
          "Content-Type": "application/json",
          "Accept": "*/*"
        }
      );
      if (legalType === "privacy-policy"
        && requestTos !== undefined
        && requestTos.content !== undefined
        && requestTos.content !== ""
        && requestTos.content.split(personalDataPolicyTranslation).length > 1
      ) {
        requestTos.content = requestTos.content.split(personalDataPolicyTranslation)[1];
      }
      setTos(requestTos);
      return requestTos;
    }
    _getTos(language);
  }, [language, legalType, personalDataPolicyTranslation]);
  return (
    <Grid container
      direction="column"
      justifyContent="center"
      width="100vw"
    >
      <Grid item>
        <NavBar color={palette.themeLightTextColor}
          fontSize={`${fontSize}vw`}
          isWithLargerThanHeight={isWithLargerThanHeight}
          highLight="projects"
        />
      </Grid>
      <TosBeautifier tos = {
        tos !== undefined && tos.content !== undefined ?
          tos.content :
          null
        } textColor = { palette.themeLightTextColor } fontSize = { fontSize }
        legalType = { legalType }/>
      <Grid item>
        <Footer fontSize={`${fontSize}vw`}
          fontColor={palette.themeLightTextColor}
          showTradeMark={true}
        />
      </Grid>
    </Grid>
  );
};

export default AiroadLegals;
