/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: first_scroll.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";
import ligths from "../../assets/media/barty_ligths.jpg";
import concert from "../../assets/media/barty_concert.jpg";

const FirstScroll = (prop) => {
  const {
    fontSize
  } = prop;

  return (
    <Grid item width = "100vw" style = {{ padding: "0 10vw 0 10vw" }}>
      <Grid container direction = "row" padding = "4vh 0 9vh 0"
      alignItems = "center"
      >
        <Grid item width = "50%" padding = "0 4vw 0 4vw">
          <Typography
            fontFamily = "MontserratRegular"
            fontSize = { fontSize }
            fontWeight = "300"
          >
            <strong>Tu passes trop de temps à planifier tes soirées, qu'à 
            en profiter ?<br/><br/> Découvre ce qu'il se passe autour de 
            toi !</strong><br/>A chaque envie, un événement différent,
            <strong> ouvre la map</strong> et laisse-toi tenter par ce 
            qu'ont à offrir les <strong>boîtes, terrasses, barbecues
              à proximité.</strong><br/>Ensuite tu n'as plus qu'à te 
            rendre sur place et <strong>profiter de la soirée</strong>,
              seul ou <strong>avec tes amis !</strong>
          </Typography>
        </Grid>
        <Grid item width = "50%" padding = "0 4vw 0 4vw">
          <img src = { ligths } alt="ligths.jpg" width = "100%"/>
        </Grid>
      </Grid>
      <Grid container direction = "row" padding = "4vh 0 9vh 0"
      alignItems = "center"
      >
        <Grid item width = "50%" padding = "0 4vw 0 4vw">
          <img src = { concert } alt="concert.jpg" width = "100%"/>
        </Grid>
        <Grid item width = "50%" padding = "0 4vw 0 4vw">
          <Typography
            fontFamily = "MontserratRegular"
            fontSize = { fontSize }
            fontWeight = "300"
          >
            <strong>Fini de devoir courir partout, pour que tout le monde soit 
            là.</strong><br/><br/>Ici, <strong>tu te contentes</strong> de 
            créer la soirée, avec<strong> des photos, la date, le lieu, puis tu 
            ajoutes tes amis à la liste des invités.</strong><br/><strong>On se 
            charge d'inviter tout le monde pour toi,</strong> et de leur 
            donner envie de venir, en plaçant ta soirée sur leur fil 
            d'actus.<strong><br/>Dès qu'ils répondent à l'invitation on te 
            notifie et on les ajoute à la conv de la soirée.</strong>
          </Typography>
        </Grid>
        
      </Grid>
  </Grid>
  );
};

export default FirstScroll;
