/**
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: http_request.js
**  File description: This file contains all the functions to HTTP request
**/

async function httpRequest(url, method, body, header) {
  let response = 0;
  let result = 0;

  try {
    response = await fetch(
      url,
      {
        // mode: "cors",
        method: method,
        body: (method === "POST" ? body : null),
        headers: header
      }
    );

    if (!response.ok) {
      throw new Error(`Erreur! status: ${response.status}`);
    }
    result = await response.json();
    return result;
  } catch (err) {
    console.log(err.message);
    return (err.message);
  }
};

export default httpRequest;
