/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: our_projects.js
**/

import React from "react";
import { FormattedMessage } from "react-intl";
import { Grid, Typography, Button } from "@mui/material";



const FooterButtons = (props) => {
  const {
    fontSize,
    fontColor
  } = props;

  return (
    <Grid container
      direction = "row"
      justifyContent = "center"
      alignItems = "center"
      spacing = { 4 }
    >
      <Grid item>
        <Button
          href = "/legal"
        >
          <Typography
            fontFamily = "MontserratRegular"
            fontSize = { fontSize }
            textTransform = "none"
            color = { fontColor }
          >
            <FormattedMessage id = "generalTermsConditions"/>
          </Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button
          href = "https://up.ultimsoft-cloud-engine.com/status/uce"
        >
          <Typography
            fontFamily = "MontserratRegular"
            fontSize = { fontSize }
            textTransform = "none"
            color = { fontColor }
          >
            <FormattedMessage id = "label_status"/>
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

const FooterTradeMark = (props) => {
  const {
    fontSize,
    fontColor
  } = props;

  return (
    <Grid item xs = { 6 } padding = "0 0 0 5vw">
      <Typography
        fontFamily = "MontserratRegular"
        fontSize = { fontSize }
        textTransform = "none"
        color = { fontColor }
      >
        Ultimsoft | Hope the future
      </Typography>
    </Grid>
  );
};

const Footer = (props) => {
  const {
    fontSize,
    fontColor,
    showTradeMark
  } = props;

  return (
    <Grid
      container
      direction = "row"
      justifyContent = "center"
      alignItems = "center"
      width = "100vw"
      height = "7vh"
      
    >
      { showTradeMark ?
        <FooterTradeMark fontColor = { fontColor } fontSize = { fontSize }/> :
        <Grid item></Grid>
      }
      <Grid item xs = { showTradeMark ? 6 : 12 }>
        <FooterButtons fontSize = { fontSize } fontColor = { fontColor }/>
      </Grid>
    </Grid>
  );
};

export default Footer;
