/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: point_six_property_rights.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";

const PointSixPropertyRights = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          6. Respect des droits de propriété intellectuelle
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Tous les droits de propriété intellectuelle sur la structure et les 
          Contenus de l’Application BARTY sont réservés. La marque BARTY a été 
          déposée par la société ULTIMSOFT SAS.<br/><br/>
          
          Tout Utilisateur s’engage à ne pas modifier les images, sons, vidéos, 
          photographies, logos, marques, éléments graphiques, textuels, 
          visuels, outils, logiciels, documents, données, etc., qui sont la 
          propriété de BARTY et sont mis à disposition pour la seule 
          utilisation normale des fonctionnalités de l’Application. Leur 
          utilisation (reproduction, représentation, publication, transmission, 
          utilisation, modification ou extraction de tout ou partie) de quelque 
          manière que ce soit et non expressément autorisée par BARTY est 
          illicite et engage la responsabilité civile et/ou pénale de son 
          auteur, susceptible d’entraîner des poursuites judiciaires à son 
          encontre.<br/><br/>

          Les visuels, photographies et œuvres figurant sur l’Application BARTY 
          sont la propriété pleine de BARTY ou des tiers qui lui ont concédé 
          une licence, libre de redevances. Les visuels et photographies 
          utilisés pour les Évènements peuvent également mentionnés un auteur.
          <br/><br/>

          Toute reproduction, représentation, utilisation ou adaptation, sous 
          quelque forme que ce soit, connue ou inconnue à ce jour, de tout ou 
          partie de ces éléments, y compris les applications informatiques, 
          sans l’accord préalable et écrit de la société ULTIMSOFT SAS, sont 
          strictement interdites, quel que soit le cadre et le support utilisés 
          pour cette utilisation, et passible de sanctions, notamment pénales, 
          comme prévu par les articles L. 716-1 et suivants du Code de la 
          propriété intellectuelle.<br/><br/>
        </Typography>
      </Grid>
      <Grid>
    </Grid>
  </Grid>
  );
};

export default PointSixPropertyRights;
