/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: point_eight_major_force.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";

const PointEightMajorForce = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          8. Force majeure
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          BARTY ne saurait être responsable du retard ou de l’inexécution des 
          Services justifiés pas un cas de force majeure, telle qu’elle est 
          définie par l’article 1218 du Code civil et par la jurisprudence des 
          cours et tribunaux français.<br/><br/>
          
          Il est précisé que, dans une telle situation, l’Utilisateur ne peut 
          réclamer le versement d’aucune indemnité et ne peut intenter aucun 
          recours à l’encontre de la société ULTIMSOFT SAS.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PointEightMajorForce;
