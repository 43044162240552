/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: point_nine_modification_cgu.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";

const PointNineModificationCgu = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          9. Modification des Conditions Générales d’Utilisation
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Nous nous réservons le droit de modifier les présentes CGU à tout 
          moment, afin notamment de se conformer à toute évolution légale, 
          jurisprudentielle, éditoriale et/ou technique.<br/><br/>

          Les Conditions applicables sont celles en vigueur au moment de 
          l’utilisation par l’Utilisateur de l’Application BARTY.<br/><br/>

          Dans l’éventualité où l’Utilisateur n’accepterait pas les nouvelles 
          CGU, il appartient à l’Utilisateur de cesser toute utilisation 
          immédiate des Services offerts par BARTY.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PointNineModificationCgu;
