/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: intro.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";
import barty from "../../assets/media/bartyHead.png";
import appStore from "../../assets/media/app_store.svg";
import googleStore from "../../assets/media/google_store.png";
import animationMp from "../../assets/media/barty_animated.mp4";
import videoCover from "../../assets/media/barty_videoCover.png";
import animationWebm from "../../assets/media/barty_animated.webm";

const Intro = (prop) => {
  const {
    fontSize
  } = prop;

  return (
    <Grid item>
      <Grid container direction = "row">
        <video poster = { videoCover }
          defaultmuted = "defaultmuted"
          muted
          playsInline
          autoPlay
          loop
          height = "60%"
          width = "50%"
        >
          <source src = { animationMp } type="video/mp4"/>
          <source src = { animationWebm } type="video/webm"/>
          Sorry, your browser doesn't support embedded videos.
        </video>
        <Grid item width = "50%" padding = "0 5vw 0 0">
          <Grid item style = {{
            marginTop: "4vh",
            marginBottom: "2vh",
          }}>
            <img style = {{
              width: "100%",
              objectFit: "contain"
            }} src = { barty } alt="bartyHead.png"/>
          </Grid>
          <Grid item>
            <Typography
              fontFamily = "MontserratRegular"
              marginTop = "0.67em"
              fontSize = { fontSize }
              fontWeight = "600"
            >
              Trouve les soirées et événements organisés par tes amis et 
              les bars autour de toi.
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              fontFamily = "MontserratRegular"
              marginTop = "0.67em"
              fontSize = { fontSize }
              fontWeight = "300"
            >
              <strong>#1</strong> des applications liées aux
              <strong> soirées</strong> et <strong>événements.</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              fontFamily = "MontserratRegular"
              marginTop = "0.67em"
              fontSize = { fontSize }
              fontWeight = "300"
            >
              <strong>Créer</strong> une soirée, ajoute 
              <strong>tes amis</strong> à la liste d'invités, les invités 
              rajoutent leurs <strong>titres préférés</strong> à la <strong>
              playlist de la soirée,</strong> et <strong>Profitez !</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              fontFamily = "MontserratRegular"
              marginTop = "0.67em"
              fontSize = { fontSize }
              fontWeight = "300"
            >
              <strong>Ton rappeur préféré, organise un showcase ? </strong>
              On se charge de te faire remonter les soirées qui te font 
              vibrer, <strong>en fonction de tes goûts.</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              fontFamily = "MontserratRegular"
              marginTop = "0.67em"
              fontSize = { fontSize }
              fontWeight = "300"
            >
              <strong>Suis les bars que tu préfères,</strong> et ne rate 
              plus <strong>leurs soirées spéciales</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              fontFamily = "MontserratRegular"
              marginTop = "0.67em"
              fontSize = { fontSize }
              fontWeight = "300"
            >
              <strong>Un guest</strong> imprévu vient performer en direct, 
              <strong>un after,</strong> ou <strong>une pool party</strong> 
              sortie de nulle part, <strong>tu es tenu au courant</strong> 
              en direct <strong>grâce à la conv</strong> de la soirée.
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              fontFamily = "MontserratRegular"
              marginTop = "0.67em"
              fontSize = { fontSize }
              fontWeight = "600"
            >
              Que tu souhaites organiser une soirée pour décompresser, 
              fêter la fin de tes partiels, ou rejoindre des concerts.<br/>
              Barty est la solution pour toi.
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              fontFamily = "MontserratRegular"
              marginTop = "0.67em"
              fontSize = { fontSize }
              fontWeight = "300"
            >
              <strong>Rejoins la liste d'invitations maintenant</strong> et
              <strong> parles-en autour de toi,</strong> pour <strong>avoir 
              accès à Barty avant tout le monde.</strong>
            </Typography>
          </Grid>
          <Grid container direction = "row" style = {{
            width: "23vw",
            textAlign: "center",
            verticalAlign: "center"
          }}>
            <Grid item style = {{
              width: "10vw",
              marginTop: "3%"
            }}>
              <a href="https://apps.apple.com/us/app/barty-trouver-ses-soir%C3%A9es/id1607391657?itsct=apps_box_badge&amp;itscg=30200">
                <img src = { appStore } alt="Download on the App Store" width = "100%"/>
              </a>
            </Grid>
            <Grid item style = {{
              width: "13vw",
            }}>
              <a href="https://play.google.com/store/apps/details?id=com.ultimsoft.barty&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img alt="Get it on Google Play" src = { googleStore } width = "100%"/>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Intro;
