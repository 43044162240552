/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: picture_selector.js
**/

import React from "react";
import { Grid } from "@mui/material";
import AIRoad from "../../assets/media/eroad_trip_detected.gif";

const PictureSelector = (props) => {
  const {
    image,
    isWithLargerThanHeight,
    mode
  } = props;
  var selectedImage = image === "AIRoad" ?
    AIRoad :
    undefined
  ;

  return (
    <Grid item
      xs = { isWithLargerThanHeight ? 6 : 12 }
      height= { isWithLargerThanHeight ?
        mode === "ownPage" ? "73vh" : "93vh" :
        mode === "ownPage" ? "40vh" : "50vh" }
      maxHeight = { isWithLargerThanHeight ?
        mode === "ownPage" ? "73vh" : "93vh" :
        mode === "ownPage" ? "40vh" : "50vh" }
    >
      <Grid container
        justifyContent = "end"
        alignContent = "end"
        height= { isWithLargerThanHeight ? 
          mode === "ownPage" ? "73vh" : "93vh" :
          mode === "ownPage" ? "40vh" : "50vh"
        }
        maxHeight = { isWithLargerThanHeight ?
          mode === "ownPage" ? "73vh" : "93vh" :
          mode === "ownPage" ? "40vh" : "50vh"
        }
      >
        <img src = { selectedImage }
          alt = { image }
          style = {{
            maxHeight: "100%",
            maxWidth: "100%"
          }}
        />
      </Grid>
    </Grid>
  );
}

export default PictureSelector;
