import React from 'react';
import { PropTypes } from 'prop-types';
import NavBarTitle from './NavBarTitle';
import { Grid } from '@mui/material';
import palette from "../../assets/styles/colors";
import NavBarButtonPages from './NavBarButtonPages';

const NavBar = (props) => {
  const {
    title,
    subTitle,
    color,
    isWithLargerThanHeight,
    fontSize,
    highLight,
    borderBottomColor
  } = props;

  return (
    <Grid
      container
      direction="row"
      height="7vh"
      width="100vw"
      alignItems="center"
      style={{
        borderBottom: borderBottomColor !== undefined ?
          "0.1vh solid " + (color === "" ? palette.grey : color) :
          null,
      }}
    >
      <Grid
        item
        xs={isWithLargerThanHeight ? 4 : 6}
        display="flex"
        paddingLeft="5vw"
      >
        <NavBarTitle title={title} subTitle={subTitle}
          color={color} fontSize={fontSize} />
      </Grid>
      <Grid
        item
        xs={isWithLargerThanHeight ? 8 : 6}
      >
        <NavBarButtonPages color={color}
          fontSize={fontSize} highLight={highLight}
        />
      </Grid>
    </Grid >
  )
}

NavBar.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  color: PropTypes.string,
  highLight: PropTypes.string
};

NavBar.defaultProps = {
  title: "Ultimsoft",
  subTitle: "| Hope the future",
  color: ""
};

export default NavBar;
