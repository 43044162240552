/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: title.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";

const Title = (props) => {
  const {
    currentProject,
    isWithLargerThanHeight,
    color
  } = props;

  return (
    <Grid item width = "100%">
      <Typography
        fontFamily = "MontserratRegular"
        fontSize = { isWithLargerThanHeight ? "12vw" : "20vw"}
        lineHeight = "normal"
        color = { color }
        textTransform = "capitalize"
        align = "center"
        width = "100%"
      >
        { currentProject }
      </Typography>
    </Grid>
  );
}

export default Title;
