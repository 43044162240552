import React from "react";
import { Typography, Button } from "@mui/material";
import { PropTypes } from "prop-types";
import palette from "../../assets/styles/colors";

const NavBarTitle = (props) => {
  const {
    title,
    subTitle,
    color,
    fontSize
  } = props;

  return (
    <Button
      href = "/"
      style={{
        textTransform: "unset"
      }}
      >
        <Typography
          fontSize = { fontSize }
          fontFamily = "PoppinsRegular"
          paddingRight = "0.4vw"
          color = { color }
          noWrap
        >
          {title}
        </Typography>
        <Typography
          color = { color === "" ? palette.grey : color }
          fontSize = { fontSize }
          fontFamily = "PoppinsRegular"
          noWrap
        >
          {subTitle}
        </Typography>
      </Button>
  )
};

NavBarTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default NavBarTitle;