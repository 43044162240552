/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: point_three_user_services.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";

const PointThreeDotOne = (prop) => {
    const {
      fontSize
    } = prop;
    return (
      <Grid
        container
        direction = "column"
      >
        <Grid item>
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratBold"
          >
            3.1. Description des Services offerts aux Utilisateurs
          </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            BARTY offre à l'Utilisateur une expérience personnalisée lui 
            permettant de :
          </Typography>
        </Grid>
        <Grid item marginLeft = "4%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            - créer son propre Évènement et d'inviter ses amis.<br/><br/>
            - créer une playlist ou liste de lecture qui peut être jouée lors 
            de l'événement.<br/><br/>
            - trouver des Évènements promus par l'Utilisateur se trouvant dans 
            la catégorie « à la Une ».<br/><br/>
            - trouver des Évènements sélectionnés par BARTY pour vous en 
            fonction de vos goûts, dans la catégorie « Sélectionné pour vous ».
            <br/><br/>
            - trouver tous les Évènements organisés par vos amis, dans la 
            catégorie « Organisé par vos amis ».<br/><br/>
            - recevoir une invitation.<br/><br/>
            - rendre un Évènement privé. Dans ce cas, l'Évènement ne sera 
            visible que par les invités.<br/><br/>
            - rendre payant un Évènement.<br/><br/>
            - communiquer avec ses amis via le chat BARTY.<br/><br/>
            - annuler un Évènement.
          </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            BARTY met également en contact les Utilisateurs pour faciliter 
            leurs déplacements dans le cadre d'un Évènement :
          </Typography>
        </Grid>
        <Grid item marginLeft = "4%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            - Depuis l'Application BARTY, l'Utilisateur a directement accès aux 
            Services de Tiers notamment l'application UBER.<br/><br/>
            - L'Utilisateur a la possibilité d'organiser son covoiturage en 
            visualisant les personnes qui se rendent à l'Évènement et celles 
            désirant recourir au covoiturage pour se rendre à l'Évènement.
          </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            En tant qu'Utilisateur, l'Application BARTY vous permet de :
          </Typography>
        </Grid>
        <Grid item marginLeft = "4%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            - consulter les Évènements diffusés.<br/><br/>
            - créer un compte personnel à tout moment afin de bénéficier des 
            Services proposés par l'Application BARTY.<br/><br/>
            - gérer/modifier ses informations personnelles renseignées.<br/>
            <br/>
            - s'inscrire à la lettre d'information de l'Application BARTY.<br/>
            <br/>
            - géolocaliser le lieu de l'Évènement.<br/><br/>
            - intégrer un Évènement dans son agenda.<br/><br/>
            - conseiller l'Évènement à un ami via des Services de Tiers.<br/>
            <br/>
            - échanger avec d'autres Utilisateurs via le Chat.<br/><br/>
            - réserver et d'acheter une place pour les Évènements payants, le 
            cas échéant.<br/><br/>
          </Typography>
        </Grid>
    </Grid>
  );
};

const PointThreeDotTwo = (prop) => {
    const {
      fontSize
    } = prop;
    return (
      <Grid
        container
        direction = "column"
      >
          <Grid item>
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratBold"
          >
            3.2 Services de Tiers inclus
          </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratRegular"
          >
            Pour les Services de Tiers, BARTY agit en qualité d'intermédiaire 
            entre vous et les entreprises tierces, pour permettre à ces 
            dernières de vous fournir les Services de Tiers.<br/><br/>
            BARTY ne fournit pas les Services de Tiers. Les Services de Tiers 
            sont fournis par des entreprises tierces indépendantes, des 
            sociétés commerciales ou autres types d'entreprises et ne sont pas 
            employés par BARTY.<br/><br/>
            Certains Services de Tiers telles que Spotify ou Risibank par 
            exemple, peuvent afficher, permettre, inclure, ou mettre à 
            disposition du Contenu, des médias, des informations ou fournir des 
            liens vers leurs Services de Tiers, vous pouvez donc être amené à 
            accepter des Terms and Conditions of Use distinctes de celles de 
            BARTY avant d'utiliser ces Services de Tiers, sans pour autant vous 
            extraire des TOS de BARTY.<br/><br/>
            Ces entreprises tierces sont libres de décider, d'accepter ou de 
            rejeter une demande. L'Utilisateur atteste avoir pris connaissance 
            des conditions générales d'utilisation des sites des entreprises 
            tierces et en accepter les termes, particulièrement en matière de 
            réutilisation du Contenu et des données personnelles.<br/><br/>
            Nous ne pouvons être tenus responsables des conditions ou mesures 
            prises par les entreprises tierces dans le cadre de l'utilisation 
            de leurs services.<br/><br/>
            <strong>Vous reconnaissez en utilisant les Services de Tiers, que 
            nous ne sommes pas responsables de l'analyse, l'examen du Contenu, 
            de la précision, la véracité, la conformité, l'exactitude et la 
            qualité ou tout aspect de ces Services de Tiers.<br/><br/>
            Nous ne garantissons ni n'approuvons le Contenu des Services de 
            Tiers et nous n'assumerons aucune responsabilité envers vous ou 
            tout autre personne dans le cadre de l'utilisation de ces Services 
            de Tiers.<br/><br/>
            Tous types de liens hypertextes, médias, texte ou tout Contenu mis 
            en ligne par les Services de Tiers ou Utilisateur relèvent de la 
            responsabilité de ces derniers, BARTY n'assumera aucune 
            responsabilité quant au Contenu des Services de Tiers ou 
            Utilisateurs.</strong>
          </Typography>
        </Grid>
    </Grid>
  );
};

const PointThreeUserServices = (prop) => {
    const {
      fontSize
    } = prop;
    return (
      <Grid
        container
        direction = "column"
      >
          <Grid item>
          <Typography fontSize = { fontSize }
            fontFamily = "MontserratBold"
          >
            3. Services aux Utilisateurs
          </Typography>
        </Grid>
        <Grid item marginLeft = "2%">
          <PointThreeDotOne fontSize = { fontSize }/>
          <PointThreeDotTwo fontSize = { fontSize }/>
        </Grid>
      </Grid>
    );
  };

export default PointThreeUserServices;
