/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: learn_more_btn.js
**/

import React from "react";
import { Grid } from "@mui/material";
import Title from "./texts/title";
import Description from "./texts/description";

const DescriptionText = (prop) => {
  const {
    isWithLargerThanHeight,
    color,
    currentProject,
    fontSize,
    mode
  } = prop;

  return (
    <Grid container
      direction = "row"
      justifyContent = "center"
      alignContent = "center"
      height = { isWithLargerThanHeight ? 
        mode === "ownPage" ? "73vh" : "93vh" :
        mode === "ownPage" ? "30vh" : "41vh"  }
      maxHeight = { isWithLargerThanHeight ?
        mode === "ownPage" ? "73vh" : "93vh" :
        mode === "ownPage" ? "30vh" : "41vh" }
      width = "100%"
    >
      { mode !== "ownPage" ?
        <Title currentProject = { currentProject} color = { color }
        isWithLargerThanHeight = { isWithLargerThanHeight }/> : ""
      }
      { mode !== "ownPage" ?
        <Description isWithLargerThanHeight = { isWithLargerThanHeight }
        color = { color } fontSize = { fontSize } textId = "AI_RoadDescription1"
        /> :
        <Description isWithLargerThanHeight = { isWithLargerThanHeight }
        color = { color } fontSize = { fontSize } 
        textId = "automaticTripDetectionDescription"/>
      }
    </Grid>
  );
}

export default DescriptionText;
