/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: description.js
**/

import React from "react";
import { FormattedMessage } from "react-intl";
import { Grid, Typography } from "@mui/material";

const Description = (prop) => {
  const {
    isWithLargerThanHeight,
    color,
    fontSize,
    textId
  } = prop;

  return (
    <Grid item width = "100%">
      <Typography
        fontFamily = "MontserratRegular"
        fontSize = { fontSize }
        lineHeight = "normal"
        fontWeight = "600"
        color = { color }
        padding = { isWithLargerThanHeight ? "0 4vw 0 4vw" : "0 11vw 0 11vw" }
        align = "center"
        width = "100%"
      >
        { <FormattedMessage id = { textId }/> }
      </Typography>
    </Grid>
  );
}

export default Description;
