/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: point_ten_use_of_app.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";

const PointTenUseOfApp = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          10. L'Utilisation de l'Application
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          L'Utilisateur s'engage à respecter les présentes CGU.<br/><br/>
          Nous nous réservons le droit de refuser l'accès au Service, 
          unilatéralement et sans notification préalable, à tout Utilisateur ne 
          respectant pas les CGU, et ce, sans aucune justification et 
          rémunération pour les pertes de quelque forme que ce soit.<br/><br/>

          Notre rôle se limite à la mise à disposition des Services aux 
          Utilisateurs.<br/><br/>

          L'Utilisateur est seul responsable des conséquences de l'utilisation 
          de l'Application BARTY.<br/><br/>

          L'Utilisateur est tenu de s'assurer que l'utilisation qu'il fait de 
          l'Application BARTY est conforme aux dispositions légales, 
          règlementaires ainsi qu'aux CGU de l'Application BARTY.<br/><br/>

          Nous ne donnons aucune garantie à l'Utilisateur quant à la conformité 
          de l'utilisation de l'Application, qu'il fait ou qu'il projette de 
          faire, aux dispositions légales et règlementaires nationales ou 
          internationales.<br/><br/>
          
          Nous mettons en œuvre tous les moyens raisonnables pour assurer un 
          accès sécurisé et de qualité au Service, mais n'est tenue à aucune 
          obligation de résultat.<br/><br/>
          
          Nous ne pouvons, en outre, être tenus responsable de tout 
          dysfonctionnement du réseau ou des serveurs ou de tout autre 
          événement échappant au contrôle raisonnable, qui empêcherait ou 
          dégraderait l'accès au Service.<br/><br/>

          L'Utilisateur est averti des aléas techniques et des interruptions 
          d'accès pouvant survenir sur l'Application. En conséquence, BARTY ne 
          pourra être tenue responsable des indisponibilités et ralentissements 
          des Services, notamment dans les cas suivants :
        </Typography>
      </Grid>
      <Grid item marginLeft = "4%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          - Interruptions momentanées de l'Application nécessaires à son 
          évolution, maintenance, ou plus généralement en cas de mise à jour de 
          certains fichiers;<br/><br/>
          - Difficultés de fonctionnement ou interruption momentanée de 
          l'Application indépendamment de la volonté de BARTY notamment en cas 
          d'interruption des services d'électricité ou de télécommunication;
          <br/><br/>
          - Défaillances ou dysfonctionnements du réseau Internet dans la 
          transmission de messages ou documents;<br/><br/>
          - Suppression, impossibilité de stockage, transmission incorrecte ou 
          inopportune des Contenus éditoriaux figurant sur l'Application.<br/>
          <br/>
          - Modification des CGU.
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Nous nous réservons la possibilité d'interrompre, de suspendre 
          momentanément ou de modifier sans préavis l'accès à tout ou partie du 
          Service, afin d'en assurer la maintenance, ou pour toute autre 
          raison, sans que l'interruption n'ouvre droit à aucun droit, aucune 
          obligation ni indemnisation.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PointTenUseOfApp;
