/**
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: colors.js
**  File description: This file contain the colors to style the app
**/

const themeDarkTextColor = "#FFFFFF";
const themeDarkBackground = "#151515";
const themeLightTextColor = "#222222";
const themeLightBackground = "#f5f7f5";
const grey = "rgb(109, 109, 109)";
const themeLightBlue = "#368AF7";
const themeDarkBlue = "#96B0F2";
const eroadBackground = "linear-gradient(to right, #12c2e9, #c471ed, #f64f59)";
const AI_RoadNextButton = "#000000";

const palette = {
  "themeDarkTextColor": themeDarkTextColor,
  "themeDarkBackground": themeDarkBackground,
  "themeLightTextColor": themeLightTextColor,
  "themeLightBackground": themeLightBackground,
  "grey": grey,
  "themeLightBlue": themeLightBlue,
  "themeDarkBlue": themeDarkBlue,
  "eroadBackground": eroadBackground,
  "AI_RoadNextButton": AI_RoadNextButton
}

export default palette;
