/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: point_seven_responsability.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";

const PointSevenDotOne = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
        <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          7.1. Responsabilité et obligations
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Nous ne sommes aucunement responsables du Contenu publiés par les 
          Utilisateurs sur BARTY, ne donnons aucune garantie, expresse ou 
          implicite, et ne sommes pas obligés d'exercer un contrôle sur la 
          qualité, la véracité ou l'exactitude des Contenus publiés.<br/><br/>

          BARTY ne valide pas le Contenu publiés privés ou publics (Événements, 
          Chats, photos de profil, les données fournies par les Utilisateurs).
          <br/><br/>

          Si nous avons connaissance qu'un Utilisateur diffuse un Contenu 
          manifestement illicite ou comportant des erreurs, nous nous réservons 
          le droit de le supprimer, sans préavis, ni indemnités, ni droit à 
          remboursement, et sans préjudice de tout autre droit.<br/><br/>

          Il est rappelé que nous sommes un tiers aux relations entre les 
          Utilisateurs de l'Application BARTY et exclut de ce fait toute 
          responsabilité à cet égard notamment dans le cadre d'une consommation 
          de stupéfiants et d'alcool.<br/><br/>

          Il est également rappelé que les Organisateurs doivent prendre toutes 
          les précautions nécessaires en termes de sécurité, de prévention pour 
          éviter tout débordement lors des Evènements. Ils sont de ce fait 
          responsables des Evènements qu'ils organisent et devront assumer les 
          conséquences de leurs agissements et de leurs invités.<br/><br/>

          Il est également rappelé que nous ne sommes pas l'Organisateur des 
          Évènements. Nous ne pouvons donc en aucun cas être tenus pour 
          responsable des Évènements auxquels nous demeurons étrangers même si 
          nous pouvons être amenés à toucher des commissions.
        </Typography>
      </Grid>
    </Grid>
  );
};

const PointSevenDotTwo = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
        <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          7.2. Limitation de responsabilité
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Nous nous engageons à mettre en œuvre tous les moyens nécessaires 
          afin d'assurer au mieux le bon fonctionnement de l'Application aux 
          Utilisateurs mais nous ne pouvons pas garantir une utilisation 
          optimale de l'Application.<br/><br/>

          Toutefois, nous déclinons toute responsabilité en cas de :
        </Typography>
      </Grid>
      <Grid item marginLeft = "4%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          - dommages directs ou indirects de toute nature causés à 
          l'Utilisateur résultant du Contenu des Evènements et/ou de l'accès, 
          de la gestion, de l'utilisation, de l'exploitation, du 
          dysfonctionnement et/ou de l'interruption de l'Application BARTY,<br/>
          <br/>

          - utilisation anormale ou exploitation illicite de l'Application 
          BARTY par tout Utilisateur,<br/><br/>

          - attaque ou piratage informatique, privation, suppression ou 
          interdiction, temporaire ou définitive, et pour quelque cause que ce 
          soit, de l'accès au réseau internet,<br/><br/>

          - mauvaise utilisation des Services de BARTY par l'Utilisateur ou de 
          faute de sa part. Elle ne saurait pas plus être engagée à raison de 
          faits imputables à un tiers,<br/><br/>

          - perte de données telles que les photos, les messages, le Contenu 
          des Evènements.
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          L'Utilisateur garantit et indemnisera à première demande la société 
          ULTIMSOFT SAS de tout préjudice et contre toute action en 
          responsabilité qui serait engagée à l'encontre de la société 
          ULTIMSOFT SAS du fait de la violation par l'Utilisateur d'un droit 
          quelconque d'un tiers, y compris d'un Utilisateur, que ce préjudice 
          résulte de l'organisation d'un Évènement, de l'utilisation faite par
          l'Utilisateur des Services de BARTY ou de toute autre fait qui lui 
          serait imputable.
        </Typography>
      </Grid>
    </Grid>
  );
};

const PointSevenResponsability = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
        <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          7. Responsabilité
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <PointSevenDotOne fontSize = { fontSize }/>
        <PointSevenDotTwo fontSize = { fontSize }/>
      </Grid>
    </Grid>
  );
};

export default PointSevenResponsability;
