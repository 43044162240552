/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: tos_definitions.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";

const TosDefinitions = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid container direction = "column">
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          DÉFINITIONS
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Chacun des termes mentionnés ci-dessous aura dans les présentes 
          Conditions Générales d’Utilisation (ci-après dénommées les « CGU ») 
          la signification suivante :
        </Typography>
      </Grid>
      <Grid item marginLeft = "4%">
      <Typography fontSize = { fontSize }
        fontFamily = "MontserratRegular"
      >
        - <strong>« Annonceur » :</strong> désigne une personne physique ou 
        structure qui publie un encart publicitaire sur BARTY.<br/><br/>

        - <strong>« Application BARTY » :</strong> désigne l’application BARTY 
        accessible sur les stores de Play Store (Android) et l’Apple Store 
        (IOS). L’application BARTY est éditée et gérée par la société ULTIMSOFT 
        SAS.<br/><br/>

        - <strong>« Évènement » :</strong> désigne l’ensemble des éléments et 
        données déposés par un Utilisateur sous sa responsabilité éditoriale, 
        en vue de publier un événement sur l’application BARTY.<br/><br/>

        - <strong>« CGU » :</strong> désigne les présentes Conditions Générales 
        d’Utilisation.<br/><br/>

        - <strong>« Compte Utilisateur » :</strong> désigne l’espace accessible 
        depuis l’application BARTY, que tout utilisateur peut se créer pour 
        bénéficier des services proposés par BARTY.<br/><br/>
        
        - <strong>« Contenu » :</strong> désigne tous les éléments publiés 
        publiquement ou non (événement, soirées, informations, chats, photos, 
        messages, textes, ...), uploadé, téléchargé, envoyé, publié, rendu 
        public, transmis, tout ou partie grâce ou par le biais des Services.
        <br/><br/>

        - <strong>« Covoiturage » :</strong> désigne une proposition de 
        Covoiturage au choix de l’Utilisateur et pour lequel il accepte de 
        transporter un autre Utilisateur en contrepartie ou pas d’une 
        participation aux frais de voyage.<br/><br/>

        - <strong>« Données d’inscription » :</strong> ensemble des 
        informations que doit fournir l’Utilisateur lors de la procédure 
        d’inscription aux services afin de permettre son identification.
        <br/><br/>
        
        - <strong>« Données Personnelles » :</strong> données à caractère 
        personnel telles que définie à l’article 4 1) du RGPD, à savoir « toute 
        information se rapportant à une personne physique identifiée ou 
        identifiable (ci-après dénommée «personne concernée»); est réputée être 
        une «personne physique identifiable» une personne physique qui peut 
        être identifiée, directement ou indirectement, notamment par référence 
        à un identifiant, tel qu’un nom, un numéro d’identification, des 
        données de localisation, un identifiant en ligne, ou à un ou plusieurs 
        éléments spécifiques propres à son identité physique, physiologique, 
        génétique, psychique, économique, culturelle ou sociale”.<br/><br/>

        - <strong>« Identifiant » :</strong> désigne l’information permettant à 
        l’Utilisateur de se connecter à l’Application BARTY; celle-ci ayant été 
        communiquée par l’administrateur au cours de l’habilitation de 
        l’Utilisateur.<br/><br/>
        
        - <strong>« Organisateur de l’Évènement » :</strong> Utilisateur ayant 
        créé et diffusé l’Évènement.<br/><br/>

        - <strong>« Service(s) » :</strong> désigne l’ensemble des services 
        rendus par BARTY par l’intermédiaire des serveurs, des APIs ou de 
        l’application.<br/><br/>

        - <strong>« Services de Tiers » :</strong> désigne les services fournis 
        par des entreprises tierces indépendantes, telles que Spotify, Google, 
        AdMob, Google Analytics. Ces services sont mis à votre disposition par 
        l’intermédiaire des Services BARTY tels que définis ci-dessous.
      </Typography>
      </Grid>
    </Grid>
  );
};

export default TosDefinitions;
