/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: tos_intro.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";

const TosIntro = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          BARTY Profitez de la vie avec vos amis powered by Ultimsoft SAS
        </Typography>
      </Grid>
      <Grid item padding = "3vh 0 0 0">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Bienvenue sur l’application BARTY administrée par la société ULTIMSOFT SAS.
        </Typography>
      </Grid>
      <Grid item padding = "3vh 0 0 0">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          Nous sommes ravis de vous compter parmi nos Utilisateurs et 
          ferons tout pour être à la hauteur de la confiance que vous 
          nous témoignez, pour que notre relation dure le plus longtemps 
          possible.
        </Typography>
      </Grid>
      <Grid item padding = "3vh 0 0 0">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Pour que cette relation soit parfaite nous avons rédigé
          ces présentes Conditions Générales d’Utilisation afin
          de protéger au mieux vos Droits, les nôtres et ceux des
          Tiers, pour vous garantir la meilleure expérience de
          notre vision de la fête.
        </Typography>
      </Grid>
      <Grid item padding = "3vh 0 0 0">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Ensemble construisons la meilleure expérience
          pour <i><strong>“Profiter de la vie avec vos amis.” - 2022 BARTY
          </strong></i>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TosIntro;
