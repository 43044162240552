/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: ultimsoft_website_tos.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";
import NavBar from "../components/NavBar/NavBar";
import { FormattedMessage } from "react-intl";
import palette from "../assets/styles/colors";
import Footer from "../components/footer/footer";

const UltimsoftWebsiteTosIntro = (prop) => {
  const { fontSize } = prop;
  return (
    <Grid item marginBottom="2vh">
      <Typography fontSize={fontSize}
        fontFamily="MontserratRegular"
      >
        <FormattedMessage id = "tos_welcome"/><br/><br/>
        <FormattedMessage id = "tos_welcome_point_one"/><br/>
        <FormattedMessage id = "tos_welcome_point_two"/><br/>
        <FormattedMessage id = "tos_welcome_point_three"/><br/>
        <FormattedMessage id = "tos_welcome_point_four"/><br/>
        <FormattedMessage id = "tos_welcome_point_five"/><br/><br/>

        <FormattedMessage id = "tos_intro_edited_by"/><br/>

        <FormattedMessage id = "tos_intro_sas"/><br/><br/>

        <FormattedMessage id = "tos_intro_vat"/><br/>
        <FormattedMessage id = "tos_intro_loc"/><br/>
        <FormattedMessage id = "tos_intro_tel"/><br/>
        <FormattedMessage id = "tos_intro_email"/><br/><br/>

        <FormattedMessage id = "tos_intro_dir"/><br/><br/>

        <FormattedMessage id = "tos_intro_hosted_by"/>
      </Typography>
    </Grid>
  );
};

const UltimsoftWebsiteTos = () => {
  const [fontSize, setFontsize] = React.useState(
    window.innerWidth > 1600 ? "1vw" :
      window.innerWidth > 1300 ? "1.2vw" :
        window.innerWidth > window.innerHeight ? "1.5vw" :
          window.innerWidth > 600 ? "2.5vw" : "2.7vw"
  );
  const [
    isWidthLargerThanHeight,
    setIsWidthLargerThanHeight
  ] = React.useState(window.innerWidth > window.innerHeight);

  React.useEffect(() => {
    function handleResize() {
      setFontsize(
        window.innerWidth > 1600 ? "1vw" :
          window.innerWidth > 1300 ? "1.2vw" :
            window.innerWidth > window.innerHeight ? "1.5vw" :
              window.innerWidth > 600 ? "2.5vw" : "2.7vw"
      );
      setIsWidthLargerThanHeight(window.innerWidth > window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
  });

  return (
    <Grid container
      direction = "column"
      justifyContent = "center"
      width = "100vw"
      backgroundColor = "black"
    >
      <Grid item>
        <NavBar color = { palette.themeDarkTextColor }
          fontSize = { fontSize }
          isWithLargerThanHeight = { isWidthLargerThanHeight }
        />
      </Grid>
      <Grid item>
        <Grid container
          padding="5vh 0 0 0"
          sx={{
            width: "90%",
            marginLeft: "5%",
            marginRight: "5%",
            textAlign: "justify",
            color: palette.themeDarkTextColor
          }}
          direction="column"
        >
          <UltimsoftWebsiteTosIntro fontSize = { fontSize }/>
          <Grid item marginBottom="2vh">
            <Typography fontSize={fontSize}
              fontFamily="MontserratRegular"
            >
              <strong><FormattedMessage id = "text_tos"/><br/><br/></strong>
              
              <FormattedMessage id = "tos_by_using"/><br/>
              <FormattedMessage id = "tos_inform_user"/><br/>
              <FormattedMessage id = "tos_you_aknowledge"/><br/>
              <FormattedMessage id = "tos_the_content"/><br/>
              <FormattedMessage id = "tos_if_you_dont_accept"/><br/>
              <FormattedMessage id = "tos_mit"/><br/><br/>

              <FormattedMessage id = "tos_title_one"/><br/><br/>

              <FormattedMessage id = "tos_title_one_one"/><br/><br/>

              <FormattedMessage id = "tos_personal_info_resp"/><br/><br/>

              <FormattedMessage id = "tos_title_one_two"/><br/><br/>

              <FormattedMessage id = "tos_point_one_two_first"/><br/>
              <FormattedMessage id = "tos_point_one_two_second"/><br/>
              <FormattedMessage id = "tos_point_one_two_third"/><br/>
              <FormattedMessage id = "tos_point_one_two_fourth"/><br/><br/>

              <FormattedMessage id = "tos_title_one_three"/><br/><br/>

              <FormattedMessage id = "tos_text_we_keep"/><br/>
              <FormattedMessage id = "tos_text_three_years"/><br/>
              <FormattedMessage id = "tos_text_ten_years"/><br/>
              <FormattedMessage id = "tos_text_update_notif"/><br/><br/>

              <FormattedMessage id = "tos_title_two"/><br/><br/>

              <FormattedMessage id = "tos_data_user_rights"/><br/><br/>

              <FormattedMessage id = "tos_data_user_rights_first"/><br/>
              <FormattedMessage id = "tos_data_user_rights_second"/><br/>
              <FormattedMessage id = "tos_data_user_rights_third"/><br/>
              <FormattedMessage id = "tos_data_user_rights_fifth"/><br/>
              <FormattedMessage id = "tos_data_user_rights_sixth"/><br/>
              <FormattedMessage id = "tos_data_user_rights_seventh"/><br/><br/>
              <FormattedMessage id = "tos_text_ask_update"/><br/>
              <FormattedMessage id = "tos_text_id_card"/><br/>
              <FormattedMessage id = "tos_text_timestamp"/><br/>
              <FormattedMessage id = "tos_title_applicable_law"/><br/>
              <FormattedMessage id = "tos_text_applicable_law"/><br/>
            </Typography>
          </Grid>
          <Grid item marginTop = "4vh">
            <Typography fontSize={fontSize} textAlign="center"
              fontFamily="MontserratRegular"
            >
              <FormattedMessage id = "text_copyright"/><br/>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Footer fontSize = { fontSize } fontColor = "white"
        showTradeMark = { true }
        />
      </Grid>
    </Grid>
  );
};

export default UltimsoftWebsiteTos;
