/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: point_one_object.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";

const PointOneObject = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          1. Objet
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          <strong>L’Application BARTY a pour vocation de mettre en relation des 
          Utilisateurs préalablement inscrits depuis l’Application BARTY afin 
          de leur permettre d’accéder aux Évènements créés par les 
          Organisateurs de l’Évènement, par l’intermédiaire de l’Application 
          BARTY.</strong><br/><br/>

          Les présentes CGU définissent les modalités d’utilisation des 
          services proposés sur l’Application BARTY. Elles sont mises à 
          disposition des Utilisateurs, des Organisateurs de l’Application 
          BARTY.<br/><br/>
          
          <strong>En utilisant BARTY, l’Utilisateur reconnaît avoir pris 
          connaissance des présentes CGU et s’engage à les respecter.</strong>
          <br/><br/>

          <strong>Toute installation de l’application BARTY effectuée à quelque 
          titre que ce soit, implique obligatoirement l’acceptation pleine et 
          entière des présentes CGU, ce qui est expressément reconnu par 
          l’Utilisateur, qui renonce, notamment, à se prévaloir de tout 
          document contradictoire qui serait inopposable à la société ULTIMSOFT 
          SAS, propriétaire de l’application BARTY.</strong><br/><br/>

          Ces présentes CGU ont notamment pour objet d’informer l’Utilisateur 
          de l’existence de droits de propriété intellectuelle devant être 
          respectés, ainsi que des modalités de collecte et de traitement des 
          informations par l’Utilisateur dans le cadre de la mise en œuvre de 
          l’application BARTY.<br/><br/>

          Le Contenu des CGU peut être amené à changer, l’Utilisateur est 
          invité par conséquent à les consulter très régulièrement.<br/><br/>

          <strong>Bien entendu, si vous n’acceptez pas les CGU vous ne devez 
          pas utiliser les Services BARTY.</strong>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PointOneObject;
