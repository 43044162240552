/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: point_five_privacy_policy.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";

const PointFiveDotOneDotFive = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          5.1.5. Durée de conservation des données personnelles
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Nous conservons vos données personnelles que pour la durée nécessaire 
          au regard des finalités de leur collecte ainsi que dans le respect de 
          la règlementation en vigueur.<br/><br/>

          A cet égard, les données des Utilisateurs sont conservées pendant la 
          durée des relations contractuelles (sauf si l'Utilisateur en demande 
          l'effacement). Dans tous les cas, les Données Personnelles effacées 
          seront archivées pendant 3 ans avant leur suppression définitive.<br/>
          <br/>

          Dans le cadre de l'utilisation des Services offrant des Options 
          payantes, elles sont conservées 10 ans à compter de la clôture de 
          l'exercice comptable.<br/><br/>

          Lorsque nous collecterons des Données à caractère personnel 
          supplémentaires de façon obligatoires, vous en serez avisé par 
          courrier électronique. Les CGU, mentions légales ainsi que la 
          politique de gestion des Données Personnelles seront modifiées et 
          adaptées en conséquence.
        </Typography>
      </Grid>
    </Grid>
  );
};

const PointFiveDotOneDotFour = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          5.1.4. La base légale du traitement
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          - Consentement de l'Utilisateur concerné;<br/><br/>
          - Exécution d'un contrat pour la mise à disposition de l'Application 
          BARTY;<br/><br/>
          - Respect d'une obligation légale;<br/><br/>
          - Intérêt légitime du responsable de traitement.<br/><br/>
        </Typography>
      </Grid>
    </Grid>
  );
};

const PointFiveDotOneDotThree = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          5.1.3. Amélioration de l'expérience Utilisateur
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          BARTY utilise, sur le fondement de l'intérêt légitime de BARTY à 
          promouvoir ses Services et améliorer sa relation Utilisateur, les 
          données collectées directement auprès de l'Utilisateur telles que des 
          commentaires ou suggestions, pourront être utilisées par BARTY, sans 
          que cela ne fasse l'objet d'aucune rémunération, et sans aucune 
          restriction ou obligation à votre égard, ceci dans l'objectif 
          d'améliorer la navigation et l'utilisation des Services.<br/><br/>

          A cet effet, vous acceptez que nous détenions tous les droits sur 
          tout matériel ou tout élément que nous développons sur la base de ces 
          données collectées et donc par la même occasion, que BARTY détienne 
          tous les droits de propriété sur les outils, fonctionnalités, 
          services, produits… développés sur la base de vos suggestions.
        </Typography>
      </Grid>
    </Grid>
  );
};

const PointFiveDotOneDotTwo = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          5.1.2. Collecte et utilisation des données personnelles
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Les Données à caractère personnel qui sont collectées varient en 
          fonction de la façon dont les services sont utilisés. Les Données à 
          caractère personnel collectées ou détenues sont directement 
          communiquées par la personne elle-même ou proviennent de tiers, ou 
          sont collectées à partir de l'activité sur l'Application BARTY et 
          de l'utilisation des Services.<br/><br/>

          Le traitement des informations communiquées par l'intermédiaire de 
          l'Application BARTY répond aux exigences légales en matière de 
          protection des données personnelles.<br/><br/>

          L'Utilisateur doit remplir un formulaire contenant leurs données.
        </Typography>
      </Grid>
      <Grid item marginLeft = "4%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          - Dans le cadre d'un compte Utilisateur, doivent nous être 
          communiqués, le nom, le prénom, la date de naissance, le genre, la 
          photo, le mail, l'usage et la zone géographique d'intérêt sont 
          facultatives mais importantes pour que BARTY cible au mieux vos 
          intérêts et attentes.
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Les informations Contenues dans la rubrique « Mes informations » sont 
          modifiables à tout moment par le titulaire du compte Utilisateur. Les 
          Données Personnelles collectées peuvent être utilisées par 
          l'Application BARTY aux fins suivantes :
        </Typography>
      </Grid>
      <Grid item marginLeft = "4%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          - la gestion, la publication et le suivi de l'Evènement.<br/><br/>
          - la création de playlist portant le nom de l'Evènement pouvant être 
          communiqué aux Services de Tiers.<br/><br/>
          - réclamations et litiges.<br/><br/>
          - l'envoi de formulaires de réponses.<br/><br/>
          - l'envoi de publicités, propositions commerciales et/ou 
          promotionnelles, émanant de BARTY. Pour ces derniers, l'envoi sera 
          effectué exclusivement par BARTY. En utilisant nos Services, vous 
          acceptez que nous vous montrions des publicités que nous croyons 
          pertinentes pour vous en fonction de vos intérêts. Nous utilisons vos 
          Données Personnelles afin de définir les publicités à vous montrer.
          <br/><br/>
          - anonymisation à des fins statistiques.
        </Typography>
      </Grid>
      <Grid item marginLeft = "6%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          <i><strong>
            Nous ne vendons pas vos Données Personnelles aux Annonceurs, 
            Services de Tiers, et ne partageons pas d'informations permettant 
            de vous identifier directement (telles que votre nom, votre adresse 
            e-mail ou toutes autres coordonnées), sauf autorisation expresse de 
            votre part.
          </strong></i>
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          À la place, les Annonceurs nous donnent des informations, telles que 
          le type d'audience à qui ils souhaitent montrer leurs publicités, et 
          nous diffusons ces publicités auprès des personnes susceptibles 
          d'être intéressées.<br/><br/>
          Nous envoyons aux Annonceurs des rapports sur les performances de 
          leurs publicités pour les aider à comprendre comment les Utilisateurs 
          interagissent avec leur Contenu.
        </Typography>
      </Grid>
    </Grid>
    );
};

const PointFiveDotOneDotOne = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          5.1.1 Responsable de traitement
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Le responsable de traitement de données à caractère personnel est 
          la société ULTIMSOFT SAS, dont le siège social est situé 1071 Route 
          de Magne - 31 470 FONSORBES, joignable aux fins des présentes à 
          l'adresse électronique suivante : data@ultimsoft.com.
        </Typography>
      </Grid>
    </Grid>
  );
};

const PointFiveDotTwo = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          5.2. Les droits liés aux données personnelles
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          L'Utilisateur dont les Données Personnelles ont été collectées et 
          traitées dans le cadre de la mise en œuvre de la présente politique 
          de confidentialité disposent des droits ci-après mentionnés :
        </Typography>
      </Grid>
      <Grid item marginLeft = "4%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          - le droit d'accéder à ses Données à caractère personnel;<br/><br/>

          - le droit de corriger toute erreur figurant sur l'Application BARTY 
          dans la limite des droits des autres Utilisateurs;<br/><br/>

          - le droit de faire effacer ses Données à caractère personnel, de 
          limiter leur traitement ou de s'y opposer;<br/><br/>

          - le droit de retirer son consentement;<br/><br/>

          - le droit de s'opposer à la réception de prospection commerciale à 
          l'avenir;<br/><br/>

          - dans certaines circonstances, le droit de veiller à ce que ces 
          informations soient transférées à un tiers.
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Toute demande d'exercice des droits d'accès, de rectification, de 
          suppression ou de limitation du traitement doit être adressée à 
          l'adresse suivante : data@ultimsoft.com accompagnée de la copie d'une 
          pièce d'identité du demandeur.<br/><br/>

          La présente politique de confidentialité a été mise à jour 19 mai 
          2022 Nous nous réservons le droit de la modifier à tout moment afin 
          de fournir une information à jour sur la façon dont nous collectons 
          et traitons les données.
        </Typography>
      </Grid>
    </Grid>
  );
};

const PointFiveDotOne = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          5.1. Protection des données personnelles
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <PointFiveDotOneDotOne fontSize = { fontSize }/>
        <PointFiveDotOneDotTwo fontSize = { fontSize }/>
        <PointFiveDotOneDotThree fontSize = { fontSize }/>
        <PointFiveDotOneDotFour fontSize = { fontSize }/>
        <PointFiveDotOneDotFive fontSize = { fontSize }/>
      </Grid>
    </Grid>
  );
};

const PointFivePrivacyPolicy = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
        <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          5. Politique de confidentialité
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <PointFiveDotOne fontSize = { fontSize }/>
        <PointFiveDotTwo fontSize = { fontSize }/>
      </Grid>
    </Grid>
  );
};

export default PointFivePrivacyPolicy;
