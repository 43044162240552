/**
** ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
** Author: Sofian Belahouel
** Filename: ./src/pages/contact.js
** File description: This file contains all the functions that allow us to
** Create Time: 2023-03-06 14:55:20
** Modified by: Sofian Belahouel
** Modified time: 2023-03-06 14:55:21
**/

import React from "react";
import { Grid, Typography, TextField, Button } from "@mui/material";
import NavBar from "../components/NavBar/NavBar";
import palette from "../assets/styles/colors";
import Footer from "../components/footer/footer";
import Colors from "../assets/styles/colors.js";
import { FormattedMessage } from "react-intl";
import httpRequest from "../backend/http_request";

const Contact = () => {
    const [subject, setSubject] = React.useState("");
    const [body, setBody] = React.useState("");
    const [isWidthBigger, setisWidthBigger] = React.useState(
        window.innerWidth > window.innerHeight
    );
    const [fontSize, setFontsize] = React.useState(
        window.innerWidth > 1600 ? "1vw" :
            window.innerWidth > 1300 ? "1.2vw" :
                window.innerWidth > window.innerHeight ? "1.5vw" :
                    window.innerWidth > 600 ? "2.5vw" : "2.7vw"
    );
    React.useEffect(() => {
        function handleResize() {
            setFontsize(
                window.innerWidth > 1600 ? "1vw" :
                    window.innerWidth > 1300 ? "1.2vw" :
                        window.innerWidth > window.innerHeight ? "1.5vw" :
                            window.innerWidth > 600 ? "2.5vw" : "2.7vw"
            );
            setisWidthBigger(window.innerWidth > window.innerHeight);
        }
        window.addEventListener("resize", handleResize);
    });

    return (
        <Grid container direction="column" justifyContent="center" width="100vw">
            <NavBar color={Colors.themeLightBlue} fontSize={fontSize}
                isWithLargerThanHeight={isWidthBigger}
                highlight="contact"
            />
            <Grid item>
                <Grid container direction="column" justifyContent="center"
                    alignItems="center" padding="0 0 2vh 0"
                    sx={{
                        width: "100%", textAlign: "justify",
                        color: palette.themeDarkTextColor
                    }}>
                    <Typography fontFamily="MontserratRegular"
                        fontSize={isWidthBigger ? "2vw" : "6vw"}
                        lineHeight="normal" color={Colors.themeLightBlue}
                        align="center" width="100%">
                        {<FormattedMessage id={"title_contact"} />}
                    </Typography>
                    <Grid item marginTop={"2.5vh"} width={isWidthBigger ? "50vw" : "90vw"}>
                        <Typography
                            fontFamily="MontserratRegular"
                            fontSize={ isWidthBigger ? "1.5vh" : "1.2vh"}
                            lineHeight="normal" color="black"
                            align="center" width="100%">
                            {<FormattedMessage id={"subtile_contact"} />}
                        </Typography>
                    </Grid>

                    <Grid item marginTop={"5vh"}>
                        <TextField color="secondary" style={{ width: isWidthBigger ? "76vw" : "90vw" }}
                        id="subject-textfield" label={<FormattedMessage id={"label_subject"} />}
                            sx={{ "label": { color: "grey" } }}
                            onChange={(event) => {
                                setSubject(event.target.value.toString().trim());
                            }} variant="outlined" value = { subject }/>
                    </Grid>
                    <Grid item marginTop={"5vh"}>
                        <TextField color="secondary" style={{ width: isWidthBigger ? "76vw" : "90vw" }}
                            id="body-textfield"
                            multiline
                            maxRows={15}
                            minRows={15}
                            sx={{ "label": { color: "grey" } }}
                            label={<FormattedMessage id={"label_message"} />}
                            onChange={(event) => {
                                setBody(event.target.value.toString().trim());
                            }} variant="outlined" value = { body }/>
                    </Grid>
                    <Grid item marginTop={"5vh"}>
                        <Button onClick={ async () => {
                            await httpRequest(
                                "https://mailer.ultimsoft-cloud-engine.com/api/v1/postMailUltimsoft",
                                "POST",
                                JSON.stringify({
                                    "subject": subject,
                                    "body": body,
                                    "metadata": "Ultimsoft website"
                                }),
                                {
                                  "Content-Type": "application/json",
                                  "Accept": "*/*"
                                }
                              );
                              setSubject("");
                              setBody("");
                        }} variant="contained" style={{ width: isWidthBigger ? "30vw" : "50vw" }} color="secondary"
                            disabled={!(subject.length > 0 && body.length > 0)}>
                            <Typography
                                fontFamily="MontserratRegular"
                                fontSize={"2vh"}
                                lineHeight="normal" color="white"
                                align="center" width="100%">
                                {<FormattedMessage id={"label_send"} />}
                            </Typography>
                        </Button>
                    </Grid>
               </Grid>
            </Grid>
            <Footer fontSize={fontSize} />
        </Grid>
    );
};

export default Contact;
