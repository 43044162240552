import React from "react";
import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import NavBarButton from "../Buttons/NavBarButton";

const NavBarButtonPages = (prop) => {
  const {
    color,
    fontSize,
    highLight
  } = prop;
  return (
    <Grid
      container
      direction="row"
      paddingRight="5vw"
      justifyContent="flex-end"
    >
      <Grid
        item
        paddingRight="1vw"
      >
        <NavBarButton
          fontColor={color}
          textButton={<FormattedMessage id="contact"/>}
          fontFamilyButton={highLight === "contact" ?
            "MontserratBold" : "MontserratRegular"
          }
          textTransformButton="unset"
          fontSizeButton={fontSize}
          route="/contact"
        />
      </Grid>
    </Grid>
  );
}

export default NavBarButtonPages;