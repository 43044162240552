/**
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: themes.js
**  File description: This file contain the themes
**/

import palette from "./colors";
import { createTheme } from '@mui/material/styles';

const themeLight = createTheme({
  palette: {
    background: {
      default: palette.themeLightBackground
    },
    text: {
      primary: palette.themeLightTextColor,
      secondary: palette.themeLightBackground
    },
    primary: {
      main: palette.themeLightTextColor
    },
    secondary: {
      main: palette.themeLightBlue
    }
  }
});

const themeDark = createTheme({
  palette: {
    background: {
      default: palette.themeDarkBackground
    },
    text: {
      primary: palette.themeDarkTextColor,
      secondary: palette.themeLightBackground
    },
    primary: {
      main: palette.themeDarkTextColor
    },
    secondary: {
      main: palette.themeDarkBlue
    }
  }
});

export {
  themeDark,
  themeLight
};
