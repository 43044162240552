/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: barty_tos.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";
import NavBar from "../components/NavBar/NavBar";
import palette from "../assets/styles/colors";
import TosIntro from "../components/barty/tos/tos_intro";
import TosDefinitions from "../components/barty/tos/tos_definitions";
import PointOneObject from "../components/barty/tos/point_one_object";
import PointTwoRegister from "../components/barty/tos/point_two_register";
import PointThreeUserServices from "../components/barty/tos/point_three_user_services";
import PointFourServicesUsage from "../components/barty/tos/point_four_services_usage";
import PointFivePrivacyPolicy from "../components/barty/tos/point_five_privacy_policy";
import PointSixPropertyRights from "../components/barty/tos/point_six_property_rights";
import PointSevenResponsability from "../components/barty/tos/point_seven_responsability";
import PointEightMajorForce from "../components/barty/tos/point_eight_major_force";
import PointNineModificationCgu from "../components/barty/tos/point_nine_modification_cgu";
import PointTenUseOfApp from "../components/barty/tos/point_ten_use_of_app";
import PointElevenApplicableLaw from "../components/barty/tos/point_eleven_applicable_law";
import PointTwelveDisagreements from "../components/barty/tos/point_twelve_disagreements";
import Footer from "../components/footer/footer";

const BartyTos = () => {
  const [fontSize, setFontsize] = React.useState(
    window.innerWidth > 1600 ? "1vw" :
      window.innerWidth > 1300 ? "1.2vw" :
        window.innerWidth > window.innerHeight ? "1.5vw" :
          window.innerWidth > 600 ? "2.5vw" : "2.7vw"
  );
  const [
    isWithLargerThanHeight,
    setIsWithLargerThanHeight
  ] = React.useState(window.innerWidth > window.innerHeight);

  React.useEffect(() => {
    function handleResize() {
      setFontsize(
        window.innerWidth > 1600 ? "1vw" :
          window.innerWidth > 1300 ? "1.2vw" :
            window.innerWidth > window.innerHeight ? "1.5vw" :
              window.innerWidth > 600 ? "2.5vw" : "2.7vw"
      );
      setIsWithLargerThanHeight(window.innerWidth > window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
  });

  return (
    <Grid container
      direction = "column"
      justifyContent = "center"
      width = "100vw"
      backgroundColor = "black"
    >
        <Grid item>
            <NavBar color = { palette.themeDarkTextColor }
                fontSize = { fontSize }
                isWithLargerThanHeight = { isWithLargerThanHeight }
                highLight = "projects"
            />
        </Grid>
        <Grid item>
            <Grid container
                padding = "5vh 0 0 0"
                sx = {{
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    textAlign: "justify",
                    color: palette.themeDarkTextColor
                }}
                direction = "column"
            >
                <Grid item marginBottom = "2vh">
                    <TosIntro fontSize = { fontSize }/>
                </Grid>
                <Grid item marginBottom = "2vh">
                    <TosDefinitions fontSize = { fontSize }/>
                </Grid>
                <Grid item marginBottom = "2vh">
                    <PointOneObject fontSize = { fontSize }/>
                </Grid>
                <Grid item marginBottom = "2vh">
                    <PointTwoRegister fontSize = { fontSize }/>
                </Grid>
                <Grid item marginBottom = "2vh">
                    <PointThreeUserServices fontSize = { fontSize }/>
                </Grid>
                <Grid item marginBottom = "2vh">
                    <PointFourServicesUsage fontSize = { fontSize }/>
                </Grid>
                <Grid item marginBottom = "2vh">
                    <PointFivePrivacyPolicy fontSize = { fontSize }/>
                </Grid>
                <Grid item marginBottom = "2vh">
                    <PointSixPropertyRights fontSize = { fontSize }/>
                </Grid>
                <Grid item marginBottom = "2vh">
                    <PointSevenResponsability fontSize = { fontSize }/>
                </Grid>
                <Grid item marginBottom = "2vh">
                    <PointEightMajorForce fontSize = { fontSize }/>
                </Grid>
                <Grid item marginBottom = "2vh">
                    <PointNineModificationCgu fontSize = { fontSize }/>
                </Grid>
                <Grid item marginBottom = "2vh">
                    <PointTenUseOfApp fontSize = { fontSize }/>
                </Grid>
                <Grid item marginBottom = "2vh">
                    <PointElevenApplicableLaw fontSize = { fontSize }/>
                </Grid>
                <Grid item marginBottom = "4vh">
                    <PointTwelveDisagreements fontSize = { fontSize }/>
                </Grid>
                <Grid item>
                  <Typography fontSize = { fontSize } textAlign = "center"
                      fontFamily = "MontserratRegular"
                  >
                      Copyright © 2021 Ultimsoft SAS. All rights reserved.
                  </Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid item>
            <Footer fontSize = { fontSize } fontColor = "white"
            showTradeMark = { true }/>
        </Grid>
    </Grid>
  );
};

export default BartyTos;
