/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: point_twelve_disagreements.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";

const PointTwelveDisagreements = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          12. Différends
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Les Parties déclarent leur intention de rechercher en priorité une 
          solution amiable à tout litige résultant de l’application ou de 
          l’interprétation des présentes CGU.<br/><br/>
          
          À cette fin, les réclamations doivent être adressées sur 
          l’Application BARTY – rubrique contact, précisant les difficultés 
          rencontrées ou les manquements constatés.<br/><br/>
          
          L’équipe de BARTY aura alors trente (30) jours calendaires pour faire 
          connaître son interprétation des événements, la proposition amiable 
          de règlement du différend ou son refus d’un règlement amiable.<br/>
          <br/>

          L’Utilisateur ayant initié le règlement amiable disposera alors à son 
          tour de trente (30) jours calendaires pour faire connaître sa 
          réponse. Si dans les trente (30) jours calendaires suivant cette 
          réponse, les Parties n’ont pas abouti à un accord amiable, 
          l’Utilisateur devra soumettre son litige, avant toute procédure 
          judiciaire, à une médiation auprès de l’Association Nationale des
          Médiateurs (ANM-CONSO) à l’adresse électronique suivante : 
          contact@anm-conso.com.<br/><br/>

          Les Parties s’engagent à garder strictement confidentiels tous les 
          échanges de paroles, de courriers, de documents qui auront lieu au 
          cours de la procédure de médiation.<br/><br/>

          En cas de réponse non satisfaisante du médiateur, l’Utilisateur 
          pourra porter le litige le concernant devant les juridictions 
          compétentes de Toulouse, même en cas de référé, d’appel en garantie 
          ou de pluralité de défendeurs.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PointTwelveDisagreements;
