/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: point_eleven_applicable_law.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";

const PointElevenApplicableLaw = (prop) => {
  const {
    fontSize
  } = prop;
  return (
    <Grid
      container
      direction = "column"
    >
      <Grid item>
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratBold"
        >
          11. Loi applicable
        </Typography>
      </Grid>
      <Grid item marginLeft = "2%">
        <Typography fontSize = { fontSize }
          fontFamily = "MontserratRegular"
        >
          Tous les litiges ou contestations qui naîtraient à l’occasion des 
          présentes CGU et/ou des Services, de leur validité, leur 
          interprétation, leur exécution ou leur inexécution, sont soumis au 
          droit français, quel que soit le pays d’origine de l’Utilisateur ou 
          le pays depuis lequel l’Utilisateur accède à l’Application BARTY, les 
          principes de conflits de lois.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PointElevenApplicableLaw;
