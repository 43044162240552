/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: projects_buttons.js
**/

import React from "react";
import { Button, Typography } from "@mui/material";

const projects = [
  {
    "name": "ASO checker",
    "url": "hide"
  },
  {
    "name": "AI Road",
    "url": "https://airoad.fr"
  },
  {
    "name": "barty",
    "url": "local"
  },
  {
    "name": "AI fingerprint",
    "url": "https://ai.ultimsoft-cloud-engine.com"
  }
];

function cleanProjectNameForUrl(projectName) {
  return projectName.replace(/\s+/g, '-').toLowerCase();
};

function getTarget(project) {
  if (project["url"].includes("http")) {
    return "_blank";
  }
  return "";
};

function getHref(project) {
  if (project["url"] === "local") {
    return `/${cleanProjectNameForUrl(project["name"])}`;
  } else if (project["url"].includes("http")) {
    return project["url"];
  }
  return "";
};

const ProjectsButtons = (props) => {
  const {
    currentProject,
    color,
    fontSize
  } = props;
  var buttonList = [];

  for (var i = 0; i < projects.length; i++) {
    if (projects[i]["url"] !== "hide") {
      buttonList.push(
        <Button
          key = { `${cleanProjectNameForUrl(projects[i]["name"])}_${i}` }
          sx = {{
            rotate: "180deg",
            margin: i <= (projects.length - 2) ?  "0 0 5vh 0" : "0"
          }}
          target = { getTarget(projects[i]) }
          href = { getHref(projects[i]) }
        >
          <Typography
            fontFamily = "MontserratRegular"
            color = { color }
            fontSize = { fontSize }
            textTransform = "capitalize"
            fontWeight = { currentProject === projects[i]["name"] ? "900" : "100"}
            sx = {{writingMode: "vertical-rl"}}
          >
            { projects[i]["name"] }
          </Typography>
      </Button>
      );
    }
  }
  return buttonList;
};

export default ProjectsButtons;
