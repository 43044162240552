/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: tos_beautifier.js
**/

import React from "react";
import { Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

const TosBeautifier = (props) => {
  const {
    tos,
    textColor,
    fontSize,
    legalType
  } = props;

  return (
    <Grid item>
      <Grid container
        sx={{
          width: "90%",
          marginLeft: "5%",
          marginRight: "5%",
          textAlign: "justify",
          color: textColor
        }}
        direction="column"
      >
        <Grid item padding = "0 0 2.5vh 0">
          <Typography
            fontFamily = "MontserratRegular"
            fontSize = { `${fontSize * 1.5}vw` }
            fontWeight = "500"
            align = "center"
            width = "100%"
          >
            { 
              legalType === "full_tos" ?
                <FormattedMessage id = "generalTermsConditions"/> :
                legalType === "privacy-policy" ?
                  <FormattedMessage id = "personalDataProcessingPolicy"/> :
                  null
            }
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ whiteSpace: 'pre-line'}} fontSize = { `${fontSize}vw` } textAlign="justify"
            fontFamily="MontserratRegular"
          >
            { tos }
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TosBeautifier;


