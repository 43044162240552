/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: index.js
**/

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import './index.css'
import { IntlProvider } from "react-intl";
import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

const messages = {
    "en": messages_en,
    "fr": messages_fr
};
const root = ReactDOM.createRoot(document.getElementById('root'));
var languageFromUrl = new URLSearchParams(window.location.search).get("lang");
var language = navigator.language.split(/[-_]/)[0];

if (languageFromUrl !== null
  && languageFromUrl !== undefined
  && languageFromUrl !== ''
  && languageFromUrl.length >= 2) {
language = (languageFromUrl[0] + languageFromUrl[1])
} else {
  languageFromUrl = new URLSearchParams(window.location.search).get("language");
  if (languageFromUrl !== null
    && languageFromUrl !== undefined
    && languageFromUrl !== ''
    && languageFromUrl.length >= 2) {
  language = (languageFromUrl[0] + languageFromUrl[1])
  }
}

root.render(
  <React.StrictMode>
      <IntlProvider
        locale = { language }
        messages = { messages[language] }
      >
        <App />
      </IntlProvider>
  </React.StrictMode>
);
