/*
**  ULTIMSOFT LABORATORY'S : HOPE THE FUTURE
**  Author: Mateo CASANOVA
**  Filename: display.js
**/

import React from "react";
import { Grid } from "@mui/material";

import palette from "../../assets/styles/colors";
import PictureSelector from "./picture_selector";
import ProjectsButtons from "./projects_buttons";
import DescriptionText from "./description_texts";


const DisplayProject = (props) => {
  const {
    currentProject,
    color,
    isWithLargerThanHeight,
    fontSize,
    mode
  } = props;

  return (
    <Grid
      container
      direction = "row"
      sx = {{
        position: "absolute",
        background: palette.eroadBackground,
        padding: "7vh 0 0 0",
        top: "0px",
        left: "0px"
      }}
      height = { mode === "ownPage" ? "80vh" : "100vh" }
      with = "100vw"
    >
      { mode !== "ownPage" ?
        <Grid item
          xs = { 1 }
        >
          <Grid container
            justifyContent = "center"
            alignContent = "start"
            height = { mode === "ownPage" ? "73vh" : "93vh" }
            maxHeight = { mode === "ownPage" ? "73vh" : "93vh" }
            direction = "column"
          >
            <ProjectsButtons currentProject = { currentProject }
              color = { color }
              fontSize = { fontSize }
            />
          </Grid>
        </Grid> : ""
      }
      
      <Grid item xs = { mode !== "ownPage" ? 11 : 12 }
        height= { mode === "ownPage" ? "73vh" : "93vh" }
        maxHeight = { mode === "ownPage" ? "73vh" : "93vh" }
      >
        <Grid container
          direction = { isWithLargerThanHeight ? "row" : "column" }
          height= { mode === "ownPage" ? "73vh" : "93vh" }
          maxHeight = { mode === "ownPage" ? "73vh" : "93vh" }
          width = "100%"
          justifyContent = "end"
          alignContent = "end"
        >
          <Grid item xs = { isWithLargerThanHeight ? 6 : 12 }
            height = { isWithLargerThanHeight ? 
              mode === "ownPage" ? "73vh" : "93vh" :
              mode === "ownPage" ? "31vh" : "41vh" }
            maxHeight = { isWithLargerThanHeight ?
              mode === "ownPage" ? "73vh" : "93vh" :
              mode === "ownPage" ? "31vh" : "41vh" }
          >
            <DescriptionText isWithLargerThanHeight = { isWithLargerThanHeight}
            color = { color } buttonColor = { palette.eroadNextButton }
            currentProject = { currentProject } fontSize = { fontSize }
            mode = { mode }/>
          </Grid>
          
          <PictureSelector
            image = { currentProject }
            isWithLargerThanHeight = { isWithLargerThanHeight }
            mode = { mode }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DisplayProject;
